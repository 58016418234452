import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Type } from "../../../../shared/components";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Component({
  selector: "app-attachments-controls",
  templateUrl: "./attachments-controls.component.html",
  styleUrls: ["./attachments-controls.component.scss"],
})
export class AttachmentsControlsComponent {
  app = getApp((app) => {
    this.app = app;
  });

  @Input() id: string = "";
  @Input() type: Type = "thread";
  @Input() isColored = true;

  @ViewChild("fileInput", { static: false }) fileInput: any;

  constructor() {}

  async changeFileInput() {
    const { id, type } = this;
    const customer = this.app.customers.expectCurrent;
    try {
      if (StringUtils.isNullOrEmpty(id)) {
        throw new Error("no doc id");
      }
    } catch (error) {
      console.log(error);
      return;
    }

    const files = this.fileInput.nativeElement.files;
    if (files == null) {
      return;
    }

    this.app.spinner.showSpinner();
    for (let i = 0; i < files.length; i++) {
      const fileLink = [customer, type, btoa(id), files[i].name].join("/");
      await this.app.file.upload(files[i], fileLink);
      await this.delay(1000);
    }
    if (type === "thread") {
      //get the thread to see the updated update_time
      await this.app.thread.getThread(id);
    }
    if (type === "ocComment") {
      //get the comments to see the updated update_time
      await this.app.OCComment.ocCommentsByPostId();
    }
    this.app.spinner.hideSpinner();
  }

  delay(ms: number) {
    console.log("upload delay");
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
