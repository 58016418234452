import { APP_TYPE, AppType } from "../app.type";
import { CustomersServiceType } from "./customers.service.type";
import {
  getCustomerSettings,
  getCustomerSettingsWithoutUser,
} from "../api.service";
import { Inject, Injectable } from "@angular/core";
import { Settings } from "../../../../shared/components";
import { touch } from "../utils/app.utils";
import { Customer } from "../../../../shared/types/customers";
import { CustomerName } from "../../../../shared/config/customers";
import { PORTAL } from "../../../../shared/constants/portal";

export const TRANSPORT_CLIENT = "transport-client";
export const NON_TRANSPORT_CLIENT = "non-transport-client";

export const RM_CLIENT = "rm-client";
export const NON_RM_CLIENT = "non-rm-client";

export const SPECIAL_CONFIGURATION = "special-configuration";
export const USUAL_CONFIGURATION = "usual-configuration";

@Injectable()
export class CustomersService implements CustomersServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get customers() {
    return [...this.app.state.customers].sort();
  }

  get current() {
    return this.app.state.customer;
  }

  get expectCurrent() {
    return this.app.expect(this.current);
  }

  getText(customer = this.expectCurrent) {
    if (customer === PORTAL) {
      return "Bahnportal";
    }
    if (customer === Customer.COOP) {
      return "COOP-M Portal";
    }
    if (customer === Customer.SSI) {
      return "SSI Schäfer";
    }
    if (customer === Customer.ATLAS) {
      return "ATLAS Elektronik";
    }
    if (customer === Customer.KATEK) {
      return "KATEK Düsseldorf";
    }
    if (customer === Customer.KATEKLEIP) {
      return "KATEK Leipzig";
    }
    if (customer === Customer.KATEKMEMM) {
      return "KATEK Memmingen";
    }
    if (customer === Customer.KATEKMAUER) {
      return "KATEK Mauerstetten";
    }
    if (customer === Customer.KATEKGRASS) {
      return "KATEK Grassau";
    }
    if (customer === Customer.MTU) {
      return "RollsRoyce Solutions";
    }
    if (customer === Customer.XLIVE) {
      return "X-LiveConnect";
    }
    if (customer === Customer.SEW) {
      return "SEW Eurodrive";
    }
    if (customer === Customer.PLASTICOMNIUM) {
      return "Plastic Omnium";
    }
    if (customer === Customer.DUOMETRIC) {
      return "DUOmetric";
    }
    if (customer === Customer.RHEINMETALLAIRDEFENCE) {
      return "RheinmetallAirDefence";
    }
    if (customer === Customer.OC) {
      return "Obsolescence Community";
    }
    return customer.toUpperCase();
  }

  /**  Used for customers list filtration */
  getLowerCaseText(customer: string) {
    if (customer === "Bahnportal") {
      return PORTAL;
    }
    if (customer === "COOP-M Portal") {
      return Customer.COOP;
    }
    if (customer === "SSI Schäfer") {
      return Customer.SSI;
    }
    if (customer === "ATLAS Elektronik") {
      return Customer.ATLAS;
    }
    if (customer === "KATEK Düsseldorf") {
      return Customer.KATEK;
    }
    if (customer === "KATEK Leipzig") {
      return Customer.KATEKLEIP;
    }
    if (customer === "KATEK Memmingen") {
      return Customer.KATEKMEMM;
    }
    if (customer === "KATEK Mauerstetten") {
      return Customer.KATEKMAUER;
    }
    if (customer === "KATEK Grassau") {
      return Customer.KATEKGRASS;
    }
    if (customer === "RollsRoyce Solutions") {
      return Customer.MTU;
    }
    if (customer === "X-LiveConnect") {
      return Customer.XLIVE;
    }
    if (customer === "SEW Eurodrive") {
      return Customer.SEW;
    }
    if (customer === "Plastic Omnium") {
      return Customer.PLASTICOMNIUM;
    }
    if (customer === "DUOmetric") {
      return Customer.DUOMETRIC;
    }
    if (customer === "Obsolescence Community") {
      return Customer.OC;
    }
    return customer.toLowerCase();
  }

  getSyncCustomers(currentCustomer = this.current) {
    if (currentCustomer === PORTAL) {
      return this.customers.filter(
        (customer) =>
          customer !== PORTAL &&
          this.getCustomerType(customer) === TRANSPORT_CLIENT
      );
    } else {
      return [PORTAL];
    }
  }

  getCustomerType(customer: string = this.app.customers.expectCurrent) {
    const nonTransportClients = [
      Customer.KOMAX,
      Customer.SCHIEBEL,
      Customer.CAE,
      Customer.CEOTRONICS,
      Customer.AIRBUSDS,
      Customer.SICK,
      Customer.MAQUETGMBH,
      Customer.MAQUETCARDIO,
      Customer.IAV,
      Customer.HERRMANNULTRASCHALL,
      Customer.SSI,
      Customer.LRE,
      Customer.KVB,
      Customer.RUAGCH,
      Customer.KATEK,
      Customer.KATEKMEMM,
      Customer.KATEKMAUER,
      Customer.KATEKLEIP,
      Customer.KATEKGRASS,
      Customer.ESYSTEMS,
      Customer.EMZ,
      Customer.ROSEN,
      Customer.TAE,
      Customer.TRIAL1,
      Customer.TRIAL2,
      Customer.TRIAL3,
      Customer.TRIAL4,
      Customer.MTU,
      Customer.ATLAS,
      Customer.DEMO,
      Customer.KARLSTORZ,
      Customer.TSK,
      Customer.XLIVE,
      Customer.STADLER,
      Customer.BEFLEX,
      Customer.AUTOFLUG,
      Customer.SEW,
      Customer.PLASTICOMNIUM,
      Customer.RHEINMETALL,
      Customer.RHEINMETALLAIRDEFENCE,
      Customer.BELIMO,
      Customer.PILZ,
      Customer.RADWELL,
      Customer.DUOMETRIC,
      Customer.KNDS,
      Customer.OC,
      Customer.HAGENUK,
    ];

    if (nonTransportClients.includes(customer)) {
      return NON_TRANSPORT_CLIENT;
    }

    return TRANSPORT_CLIENT;
  }

  getRMCustomers(): string {
    const rmClients = [
      Customer.KOMAX,
      Customer.SCHIEBEL,
      Customer.CEOTRONICS,
      Customer.AIRBUSDS,
      Customer.SICK,
      Customer.MAQUETGMBH,
      Customer.MAQUETCARDIO,
      Customer.IAV,
      Customer.HERRMANNULTRASCHALL,
      Customer.SSI,
      Customer.LRE,
      Customer.KVB,
      Customer.RUAGCH,
      Customer.KATEK,
      Customer.KATEKMEMM,
      Customer.KATEKMAUER,
      Customer.KATEKLEIP,
      Customer.KATEKGRASS,
      Customer.ESYSTEMS,
      Customer.EMZ,
      Customer.ROSEN,
      Customer.TAE,
      Customer.TRIAL1,
      Customer.TRIAL2,
      Customer.TRIAL3,
      Customer.TRIAL4,
      Customer.MTU,
      Customer.NS,
      Customer.MRCE,
      Customer.DEMO,
      Customer.ATLAS,
      Customer.KNDS,
      Customer.KARLSTORZ,
      Customer.TSK,
      Customer.XLIVE,
      Customer.STADLER,
      Customer.BEFLEX,
      Customer.AUTOFLUG,
      Customer.SEW,
      Customer.PLASTICOMNIUM,
      Customer.RHEINMETALL,
      Customer.RHEINMETALLAIRDEFENCE,
      Customer.CAE,
      Customer.BELIMO,
      Customer.PILZ,
      Customer.RADWELL,
      Customer.DUOMETRIC,
      Customer.HAGENUK,
    ];

    if (rmClients.includes(this.app.customers.expectCurrent)) {
      return RM_CLIENT;
    }

    return NON_RM_CLIENT;
    /** bvg, abc, rheinbahn, db, omp, coop */
  }

  hasRM(): boolean {
    if (this.getRMCustomers() === RM_CLIENT) {
      return true;
    }

    return false;
  }

  // using RM module but with "specialities"
  getSpecialCustomers() {
    return USUAL_CONFIGURATION;
  }

  setData(customer: CustomerName, settings: Settings) {
    const buffer = touch(
      () => this.app.state.buffer,
      (arg) => {
        arg[customer] = touch(
          () => arg[customer],
          (arg) => {
            arg.settings = settings;
            if (arg.docs == null) {
              arg.docs = {};
            }
          }
        );
      }
    );

    this.app.state.next({ buffer });
  }

  async update(customer = this.current, isPcn?: boolean) {
    if (customer == null) {
      return;
    }
    this.app.state.next({ customer });
    let settings: Settings;
    if (isPcn) {
      settings = await getCustomerSettingsWithoutUser(customer);
    } else {
      settings = await getCustomerSettings(customer);
    }
    this.app.customers.setData(customer, settings);
    this.app.proxy.settings = settings;
  }

  hasPortal() {
    // user has acces to portal
    const hasUserPortalAccess = this.app.customers.customers.includes(
      Customer.OMP
    );
    let hasClientPortalAccess = false;
    if (this.app.customers.getCustomerType() === TRANSPORT_CLIENT) {
      hasClientPortalAccess = true;
    }
    if (hasUserPortalAccess && hasClientPortalAccess) {
      return true;
    }
    return false;
  }
}
