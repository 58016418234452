import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Subscription, interval } from "rxjs";
import { completeMFA } from "../api.service";
import { MFADetails } from "../../../../shared/models/MFA-details";

@Component({
  selector: "MFA-complete",
  templateUrl: "./MFA-complete.component.html",
  styleUrls: ["./MFA-complete.component.scss"],
})
export class MFACompleteComponent implements OnInit {
  // MFA code is 8-digit for karlstorz and 6-digit for the rest of our clients
  otpLength = Number(localStorage.getItem("mfaCodeLength"));
  otpCode: string[] = new Array(this.otpLength).fill("");
  otpArray: number[] = Array(this.otpLength)
    .fill(0)
    .map((x, i) => i);
  countdownTime: string = "05:55";
  remainingTime: number = 5 * 60 + 55; // 5:55 minutes in seconds

  private _countdownSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    this.startCountdown();
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;

    if (event.key === "Backspace") {
      if (!input.value && index > 0) {
        // Move focus to the previous input and clear its value
        const prevInput = document.getElementById(
          `otp-${index - 1}`
        ) as HTMLInputElement;
        prevInput.focus();
        prevInput.value = "";
        this.otpCode[index - 1] = "";
      } else if (input.value) {
        // If there is a value, allow it to be cleared
        input.value = "";
        this.otpCode[index] = "";
      }
    }
  }

  // Used to handle if a paste code occurred
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pasteData = event.clipboardData?.getData("text") || "";
    const pasteOtp = pasteData.split("").slice(0, this.otpLength);

    pasteOtp.forEach((char, index) => {
      this.otpCode[index] = char;
    });
  }

  // Checks if the authentification code is correct
  async submitCode() {
    const MFACode = this.otpCode.join("");
    const username = localStorage.getItem("username");

    this.app.hasError = false;
    this.app.errorText = "";

    if (username == null) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.auth.loginName;
      return;
    }

    const details: MFADetails = {
      username: username,
      authorizationCode: MFACode,
    };

    const result = await completeMFA(details);

    if (result.authenticated) {
      await this.app.auth.handleAuthentication(result);

      // Delete username and mfaCodeLength from localStorage once the authentification is done with success
      localStorage.removeItem("username");
      localStorage.removeItem("mfaCodeLength");
    } else {
      this.app.hasError = true;
      this.app.errorText = this.app.text.auth.MFAError;
    }
  }

  // Start the countdown from 5:55 minutes
  startCountdown() {
    const timer = interval(1000); // Emit every second (1000 ms)
    this._countdownSubscription = timer.subscribe(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
        this.updateCountdownDisplay();
      } else {
        this._countdownSubscription.unsubscribe(); // Stop the timer when countdown is finished
      }
    });
  }

  // Updates the countdownTime in "MM:SS" format
  async updateCountdownDisplay() {
    const minutes: number = Math.floor(this.remainingTime / 60);
    const seconds: number = this.remainingTime % 60;

    this.countdownTime = `${this.padZero(minutes)}:${this.padZero(seconds)}`;

    // Redirect to the login page when countdown reach 00:00
    if (minutes === 0 && seconds === 0) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.auth.MFASessionExpired;

      await this.app.routing.navigate("login");
    }
  }

  // Ensures that numbers less than 10 are prefixed with a 0
  padZero(value: number) {
    return value < 10 ? `0${value}` : value.toString();
  }

  // Remove non-numeric characters from otp-input if it's the case & focus the next input
  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, "");

    if (input.value && index < this.otpLength - 1) {
      const nextInput = document.getElementById(
        `otp-${index + 1}`
      ) as HTMLInputElement;
      nextInput.focus();
    }
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks when the component is destroyed
    if (this._countdownSubscription) {
      this._countdownSubscription.unsubscribe();
    }
  }
}
