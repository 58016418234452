export const API = "api";
export const API_LOGIN = "login";
export const API_MFA = "mfa";
export const API_RESET = "reset-password";
export const API_CHECK = "check";
export const API_VERIFY_PASSWORD = "verify-password";
export const API_SETTINGS = "settings";
export const API_DOCS = "docs";
export const API_FILES = "files";
export const API_HISTORY = "history";
export const API_MANUFACTURERS = "manufacturers";
export const API_UPDATE_MANUFACTURER = "update-manufacturer";
export const API_BULK_MANUFACTURERS = "bulk-manufacturers";
export const API_FILTER_ALL_MANUFACTURERS = "filter-all-manufacturers";
export const API_MANUFACTURER_NAMES = "get-names";
export const API_STATUS_CHART_HISTORY = "status-chart-history";
export const API_MATCH = "match-manufacturer";
export const API_PARTS = "parts";
export const API_ASSEMBLIES = "assemblies";
export const API_FILTER_ASSEMBLIES = "filter-assemblies";
export const API_RM_SEARCH = "rm-search";
export const API_FILTER_PARTS = "filter-parts";
export const API_FILTER_TREE = "filter-tree";
export const API_BULK_PARTS = "bulk-parts";
export const API_PART_NUMBERS = "partNumbers";
export const API_ALERTS = "alerts";
export const API_CHANGES = "changes";
export const API_SUBSTITUTES = "manufacturer-substitutes";
export const API_TRAINS = "trains";
export const API_IMPACTS = "impacts";
export const API_INTERNALITEMS = "internalItem";
export const API_OMF_NUMBERS = "omf-numbers";
export const API_ALL_OMFNUMBERS = "allOmfNumbers";
export const API_SYNC = "sync";
export const API_PROFILE = "profile";
export const API_USER_SETTINGS = "user-settings";
export const API_USERS = "users";
export const API_USERS_COMPRESSED = "users";
export const API_PERSON = "person";
export const API_IDS_BY_TYPE = "ids-by-type";
export const API_DOCS_BY_TYPE = "docs-by-type";
export const API_TASKS = "tasks";
export const API_PCNS = "pcns";
export const API_THREAD = "thread";
export const API_POST = "post";
export const API_GRAPHS = "graphs";
export const API_APPLICATION_AREA = "applicationArea-options";
export const API_GET_FILTERSERVICE = "get-filter";
export const API_WORK_HISTORY = "work-history";
export const API_WORK = "work";
export const API_DINCODE_RESP = "dinCodeResponsible";
export const API_COMMODITY_RESP = "commodityGroup";
export const API_VEHICLE_RESP = "vehicleResponsible";
export const API_PCN_GENERATOR = "pcn-generator";
export const API_MESSAGE = "message";
export const API_SAVE_PASS = "save-password";
export const API_CHECK_TOKEN = "check-token";
export const API_RESET_TOKEN = "reset-token";
export const API_RESET_CHECK = "check-reset-token";
export const API_WORKFLOW = "workflow";
export const API_OCPOST = "oc-post";
export const API_OCCOMMENT = "oc-comment";
