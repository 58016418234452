import { updateAttachmentTags } from "../api.service";
import { AttachmentServiceType } from "./attachment.service.type";
import { tagModel } from "./attachment.component";
import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Customer } from "../../../../shared/types/customers";

@Injectable()
export class AttachmentService implements AttachmentServiceType {
  deletedTagSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async addTag(fileDetails: any, tagModel: tagModel, fileLink: string) {
    let endcodedId = fileLink.split("/")[2];
    let decodedId = atob(fileLink.split("/")[2]);
    let decodedFileLink = fileLink.replace(endcodedId, decodedId);

    if (fileDetails.tags == null) {
      fileDetails.tags = [];
    }
    fileDetails.tags.push(tagModel["attachment.tag"]);

    const result = await updateAttachmentTags(
      decodedFileLink,
      tagModel["attachment.tag"],
      "add"
    );

    if (result.ok) {
      const [, type, id] = fileLink.split("/");
      if (type === "thread") {
        await this.app.thread.getThread(id);
      }

      this.updateCurrentModel(type, id, result.rev);
      if (this.app.customers.expectCurrent !== Customer.OC) {
        this.app.attachments.getAllAttachments();
      } else {
        this.app.attachments.getAllOCAttachments();
      }
    }
  }

  async deleteTag(fileDetails: any, tag: string, fileLink: string) {
    const index = fileDetails.tags.indexOf(tag);
    let endcodedId = fileLink.split("/")[2];
    let decodedId = atob(fileLink.split("/")[2]);
    let decodedFileLink = fileLink.replace(endcodedId, decodedId);

    if (index > -1) {
      fileDetails.tags.splice(index, 1);
    }
    const result = await updateAttachmentTags(decodedFileLink, tag, "delete");

    if (result.ok) {
      const [, type, id] = fileLink.split("/");
      this.updateCurrentModel(type, id, result.rev);
    }
    this.deletedTagSubject.next(true);
  }

  getDocNameByFieldId(fieldId: string) {
    let fileName: string = "";
    const docType: string = fieldId.split(".")[0];

    if (docType === "manufacturer") {
      // Document attached is a link
      fileName = this.app.manufacturer.currentManufacturer[fieldId];
      if (this.app.field.isLink(fileName)) {
        return fileName;
      }
    }

    // Document attached is a file attached to the current document in _attachments
    const fieldName = fieldId.split(".")[1];
    const files = this.app.docs.getAttachmentHistoryByDocType(docType);

    if (files == null || fieldName == null) {
      return "";
    }

    const fileNames = Object.keys(files);
    fileNames.forEach((name) => {
      if (files[name].fieldId === fieldName) {
        fileName = name;
      }
    });
    return fileName;
  }

  private async updateCurrentModel(type: string, id: string, rev: string) {
    if (type === "thread") {
      this.app.thread.thread["thread._rev"] = rev;
    }

    if (type === "post") {
      this.app.post.comments.forEach((comment) => {
        if (comment._id === id) {
          comment._rev = rev;
        }
      });
      this.app.post.solutions.forEach((comment) => {
        if (comment._id === id) {
          comment._rev = rev;
        }
      });
    }
  }
}
