import * as JSZip from "jszip";
import { AlertData } from "../../../../shared/models/alert";
import { AlertLevel, ThreadServiceType } from "./thread.service.type";
import { APP_TYPE, AppType } from "../app.type";
import { attachmentsXmlTemplate } from "./thread-xml";
import { BehaviorSubject } from "rxjs";
import { BILD_IMAGE } from "../file/file.service";
import { CodeGenerator } from "../field/code-generator";
import { CommodityGroupResponsible } from "../../../../shared/models/commodityGroupResponsible";
import { commodityMap } from "./commodityClassMap";
import { Customer } from "../../../../shared/types/customers";
import { DinCodeResponsible } from "../../../../shared/models/dinCodeResponsible";
import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import { getDataXml } from "./xml";
import { hasChanges, userEditedFields } from "../utils/app.utils";
import { Impact } from "../../../../shared/models/impact";
import { InternalItem } from "../../../../shared/models/internalItem";
import { OmfFormat } from "../../../../shared/models/omf-number";
import { Row } from "../tree/tree.util";
import { saveAs } from "file-saver-es";
import { Thread, ThreadBulk } from "../../../../shared/models/thread";
import { FieldTypes } from "../../../../shared/settings/settings";
import {
  doc2Model,
  getHistory,
  lastOmfNumbers,
  allOmfNumbers,
  getCustomerSettings,
  getDocsByType,
  getThread,
  updateThread,
  getImpacts,
  deleteThread,
  model2Doc,
  getDocsByOmfNumber,
  downloadFile,
  getThreadsCompressed,
  getAllThreads,
  getExternalCases,
  getDinCodeDocument,
  getCommodityGroupDocument,
  getThreadsbyPartNumbers,
  checkIfTitleIsDuplicated,
  getParts,
  getManufacturerByMPN,
} from "../api.service";
// import { formatDate } from "@angular/common";
import {
  addDays,
  formatDatetime,
  formatDate,
  toDateString,
} from "../utils/date.util";
import {
  RM_CLIENT,
  TRANSPORT_CLIENT,
  USUAL_CONFIGURATION,
} from "../customers/customers.service";
import { Manufacturer, SeMatch } from "../../../../shared/models/manufacturer";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { CustomerName } from "../../../../shared/config/customers";
import { setCreateTimeAndUpdateTime } from "../import/import.utils";
import { UserOptions } from "../../../../shared/models/user";
@Injectable()
export class ThreadService implements ThreadServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  thread: any = {} as Thread;
  threadDoc: any = {} as Thread; //will be removed once model won't have "thread."
  cleanModel: any = {} as Thread;
  id: string | null = null;
  isNew: boolean = false;
  isInCreateMode: boolean = true;
  threads: any;

  threadsByPartNumber: Thread[] = [];
  boxesLeftCreateCase: string[] = [];
  existingCasesPerCpn: { omfNumber: string; id: string }[] = [];
  existingCasesPerMpn: { omfNumber: string; id: string }[] = [];
  existingCasesPerPcnId: { omfNumber: string; id: string }[] = [];
  isFavorite: boolean = false;
  image: string = "";
  externalImage: string = "";
  hasPermissionToEdit: boolean = false;
  hasPermissionToDeleteImage: boolean = false;
  hasDeletePermission: boolean = false;
  threadsCompressed: Partial<Thread>[] = [];
  threadsByPartNumbers: Partial<Thread>[] = [];
  ids: string[] = [];
  favSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  threadSubject: BehaviorSubject<Thread> = new BehaviorSubject<Thread>(
    {} as Thread
  );
  creditsSubject: BehaviorSubject<Thread> = new BehaviorSubject<Thread>(
    {} as Thread
  );
  modalSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
  @Output() saved = new EventEmitter<boolean>();

  externalChanges: boolean = false; //used only with applicationArea
  exportType: string = "All Cases";
  disabledSaveButton: boolean = false;
  threadReady: boolean = false;

  showMore: boolean = false;
  headlineColor: string = "";
  textColor: string = "";
  titleColor: string = "";
  isCaseDuplicated: boolean = false;
  cpnExist: boolean = false;
  mpnExist: boolean = false;
  currentExistingCases: any = {};
  isCreatedFromAlert: boolean = false;

  hidePost() {
    this.showMore = !this.showMore;
  }

  async getThread(docId: string) {
    if (docId != null) {
      const thread = await getThread(this.app.customers.expectCurrent, docId);
      this.isNew = false;
      this.isInCreateMode = false;

      //update stepper using the threaSubject & thread credits using the creditsSubject
      this.threadSubject.next(thread);
      this.creditsSubject.next(thread);

      //doc2Model function will be removed once model won't have "thread."
      this.cleanModel = doc2Model("thread", thread);
      this.thread = doc2Model("thread", thread);

      //set omfNumber to be the id of the current thread
      this.id = this.thread[this.app.fieldId.thread.omfNumber];
      this.app.state.next({ omfNumber: this.id });
    }
  }

  get modelHasChanges() {
    return hasChanges(this.cleanModel, this.thread);
  }

  async getAllThreads() {
    if (this.app.customers.expectCurrent !== Customer.OMP) {
      this.threads = await getAllThreads(this.app.customers.expectCurrent);
    } else {
      this.threads = await getDocsByType(
        this.app.customers.expectCurrent,
        "thread"
      );
    }
    return this.threads;
  }

  async getHistory() {
    if (this.id == null) {
      throw new Error("id is null");
    }
    return await getHistory(this.id, this.app.customers.expectCurrent);
  }

  getImage(
    id = this.thread["thread._id"],
    customer = this.app.customers.expectCurrent
  ) {
    this.image = [customer, "thread", btoa(id), BILD_IMAGE].join("/");
    return this.image;
  }

  getImageExternal(): void {
    const { app } = this;

    if (this.thread["thread._id"] !== null && this.id !== "OMF") {
      const fileLink = this.getImage();
      const thread = model2Doc("thread", this.app.thread.thread);
      const imageExists = app.file.exists(thread);
      const hasBildImage = this.hasBildImage();

      if (imageExists || hasBildImage) {
        this.externalImage = app.file.getUrl(fileLink);
      } else {
        // this.externalImage = "";
        this.externalImage = !StringUtils.isNullOrEmpty(
          this.app.thread.thread["thread.externalImageSource"]
        )
          ? this.app.thread.thread["thread.externalImageSource"]
          : "";
      }
    }
  }

  isEditMode() {
    if (this.app.unlockedId === this.app.thread.id) {
      return true;
    }
    if (this.app.thread.isNew) {
      return true;
    }
    return false;
  }
  async prepareThreadData(threadId: string | null) {
    if (threadId == null) {
      return;
    }
    await this.app.thread.checkRMData();
    await this.app.post.getThreadPosts(
      this.app.customers.expectCurrent,
      threadId
    );
    this.isFav(this.app.thread.thread["thread._id"]);
    this.getImageExternal();
    this.hasEditPermission();
    this.hasPermissionToDelete();
    this.hasDeleteImagePermission();
    await this.app.impacts.getImpacts(threadId);
    if (this.app.customers.expectCurrent === Customer.OMP) {
      await this.app.sync.getOmpSyncDoc(threadId);
    } else {
      await this.app.sync.getClientSyncDoc(
        this.app.customers.current,
        threadId
      );
    }
  }

  async getThreadInitFunctions() {
    // this.app.field.changeFieldSettings(
    //   this.app.fieldId.thread.artNumber,
    //   FieldTypes.typeahead
    // );
    // this.app.field.changeFieldSettings(
    //   this.app.fieldId.thread.crtNumber,
    //   FieldTypes.typeahead
    // );
    // this.app.field.changeFieldSettings(
    //   this.app.fieldId.thread.creator,
    //   FieldTypes.typeahead
    // );

    // needed for tasks(all clients - except omp and coop), db responsibles and bvg - client responsible field
    if (
      this.app.customers.expectCurrent !== Customer.OMP &&
      this.app.customers.expectCurrent !== Customer.COOP
    ) {
      await this.app.users.getUsersWithRoles();
    }

    if (this.app.customers.expectCurrent === Customer.DB) {
      await this.app.users.getComponentResponsibles();
      // await this.app.users.getUsersWithRoles();
    }
    if (this.app.customers.expectCurrent === Customer.COOP) {
      this.app.productCategory.getProductCategories();
    }

    // needed data for cpn/ mpn dropdowns, read mode - to display the cpn/mpn as a link
    if (
      this.app.customers.getRMCustomers() === RM_CLIENT &&
      this.app.customers.getSpecialCustomers() === USUAL_CONFIGURATION
    ) {
      // await this.app.filterList.getTypeaheadOptionsForFilters();

      await this.app.manufacturer.getManufacturerIdByMpn(
        this.thread[this.app.fieldId.thread.crtNumber]
      );
      //get corresponing mpn/cpn to be displayed in the dropdowns
      await this.prepareDataForDropdown();
    }

    //used to get all the cases by cpn and display a message if the choosen cpn/mpn/pcnId is used in any other thread
    this.setFieldsValues();
    this.app.field.changeFieldSettings(
      this.app.fieldId.thread.pcnID,
      FieldTypes.typeahead
    );
    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.app.field.getFieldSettings(this.app.fieldId.thread.creator).type =
        "typeahead";
      this.app.field.getFieldSettings(
        this.app.fieldId.thread.creator
      ).required = false;
      // this.app.field.getTypeAheadOptions(this.app.fieldId.thread.creator);
    }
  }

  async setFieldsValues() {
    await this.app.thread.getCasesByPartNumbers();
    let model = this.app.thread.thread;
    let fields = Object.keys(model);
    fields.forEach((field) => {
      if (
        field === "thread.pcnID" ||
        field === "thread.artNumber" ||
        field === "thread.crtNumber"
      ) {
        let value = model[field];
        if (value != null) {
          this.app.thread.getListOfCasesByField(field, value);
        }
      }
    });
  }

  async getListOfCasesByField(
    field: "thread.artNumber" | "thread.crtNumber" | "thread.pcnID",
    value: string
  ) {
    value = value.trim();
    switch (field) {
      case "thread.artNumber":
        /** get the list of cases created by this cpn */
        this.app.thread.getCasesByCPN(value, "", "");
        break;
      case "thread.crtNumber":
        /** get the list of cases created by this mpn */
        this.app.thread.getCasesByCPN("", value, "");
        break;
      case "thread.pcnID":
        /** get the list of cases created with this pcnId */
        this.app.thread.getCasesByCPN("", "", value);
        break;
      default:
        return;
    }
  }

  async save(
    thread: Thread,
    customer: CustomerName = this.app.customers.current
  ) {
    this.app.spinner.showSpinner();
    const newThread = this.isNew;
    const syncedCustomer = this.app.sync.syncInfo.syncedFromCustomer;
    let id: string | null;
    this.disabledSaveButton = true;

    //Exceptions for the synced cases coming from abc client
    if (syncedCustomer === Customer.ABC && customer === Customer.OMP) {
      id = this.app.sync.syncInfo.syncedDoc["thread.omfNumber"];
    } else {
      //generate again the omfNumber to make sure that meanwhile it was not create another case with the same omfNumber
      id = await this.setCaseId();
    }

    if (!this.checkPcnLinkField(thread)) {
      this.disabledSaveButton = false;
      this.app.spinner.hideSpinner();
      return;
    }

    if (id == null) {
      return;
    }

    thread = this.app.field.setTwoDecimalsOnNumberFields(thread);

    // model2Doc function will be removed once model won't have "thread."
    this.threadDoc = model2Doc("thread", thread);
    this.threadDoc.omfNumber = id;

    /* below line of code is used in the beginning of save function is to get the fields that are edietd by the user which would beneeded to display in history and to avoid displaying dependent fields in history section*/
    const fields = userEditedFields(this.cleanModel, thread);
    const threadEditFields = fields.filter(
      (val: any) => val != undefined && val != "threadEditFields"
    );
    this.threadDoc.threadEditFields = threadEditFields;
    let statusOptions = this.app.field.getOptions(
      this.app.fieldId.thread.omfStatus
    );

    //Customer customizations before saving
    if (customer === Customer.DEMO || customer === Customer.TRIAL4) {
      this.setWarehouseRangesInfo(this.threadDoc);

      //to be deleted once we remove "thread."
      this.thread["thread.totalConsumation"] = this.threadDoc.totalConsumation;
      this.thread["thread.stockRangeCR"] = this.threadDoc.stockRangeCR;
      this.thread["thread.stockRangeCons"] = this.threadDoc.stockRangeCons;
      this.thread["thread.stockRangeRequired"] =
        this.threadDoc.stockRangeRequired;
      this.thread["thread.closingDateAfterCons"] =
        this.threadDoc.closingDateAfterCons;
      this.thread["thread.closingDateRequired"] =
        this.threadDoc.closingDateRequired;
      this.thread["thread.closingDateCR"] = this.threadDoc.closingDateCR;
    }
    if (customer === Customer.NS) {
      if (this.threadDoc.artNumber != null && this.threadDoc.omfShortDescr) {
        this.threadDoc.title =
          this.threadDoc.artNumber + "-" + this.threadDoc.omfShortDescr;
      }
    }

    if (customer === Customer.AUTOFLUG) {
      const isDuplicated = await this.checkIfTitleIsDuplicated();

      if (isDuplicated) {
        this.setDuplicateTitleError();
        return;
      }
    }

    /** Set case closure details - closedBy and closedOn and remove the due date*/
    if (
      this.threadDoc.threadEditFields.includes("omfStatus") &&
      statusOptions.findIndex((s) => s === this.threadDoc.omfStatus) ===
        statusOptions.length - 1
    ) {
      this.threadDoc.closedBy = this.app.user;
      this.threadDoc.closedOn = new Date().getTime();
      this.threadDoc.dueDate = "";
    }

    /** Remove case closure details - closedBy and closedOn if case is reopened */
    if (
      this.threadDoc.closedBy !== "" &&
      this.threadDoc.closedOn !== "" &&
      this.threadDoc.threadEditFields.includes("omfStatus") &&
      statusOptions.findIndex((s) => s === this.threadDoc.omfStatus) <
        statusOptions.length - 1
    ) {
      this.threadDoc.closedBy = "";
      this.threadDoc.closedOn = "";
    }

    // isPrevStatusChange - used only for DB client to save the correct data in history
    let isPrevStatusChange = false;
    let isPrevStatusSet = false;
    let isNextStatusSet = false;

    if (this.app.customers.expectCurrent === Customer.DB && !newThread) {
      const result = this.getStatusChanges();
      isPrevStatusSet = result.isPrevStatusSet;
      isNextStatusSet = result.isNextStatusSet;
    }

    /** Remove empty spaces */
    this.threadDoc.title = this.threadDoc.title.trim();

    //save thread
    let result: any = {} as Thread;
    result = await updateThread(customer, this.threadDoc, isPrevStatusChange);

    //update stepper using the threaSubject & thread credits using the creditsSubject
    this.creditsSubject.next(result);
    this.threadSubject.next(result);

    this.cleanModel = doc2Model("thread", result);
    this.thread = doc2Model("thread", result); // doc2Model function will be removed once model won't have "thread."
    this.externalChanges = false;
    this.app.unlockedId = null;

    // Get the tasks updated in case: status, employee OM, deputy or component responsible were changed
    if (customer === Customer.DB) {
      if (isPrevStatusSet || isNextStatusSet) {
        await this.app.tasks.deleteUncompletedTasksByStatus();
        // If tasks have been updated we need to get the thread again to make sure postCount is updated
        await this.app.thread.getThread(this.app.thread.thread["thread._id"]);
      }

      this.app.post.getThreadPosts(
        this.app.customers.expectCurrent,
        this.thread["thread.omfNumber"]
      );
    }

    // Get the tasks updated in case: status, employee OM, deputy or component responsible were changed
    if (customer === Customer.KNDS) {
      if (isPrevStatusSet || isNextStatusSet) {
        // If tasks have been updated we need to get the thread again to make sure postCount is updated
        await this.app.thread.getThread(this.app.thread.thread["thread._id"]);
      }

      this.app.post.getThreadPosts(
        this.app.customers.expectCurrent,
        this.thread["thread.omfNumber"]
      );
    }

    //if syncInfos exist copy attachments info and create the syncDoc
    const { syncedFromCustomer, syncedDoc } = this.app.sync.syncInfo;
    if (syncedFromCustomer != null && syncedDoc["thread.omfNumber"] != "") {
      await this.app.sync.sync(
        "thread",
        syncedDoc["thread.omfNumber"],
        customer,
        syncedFromCustomer
      );
    }

    //if uploadedFileInfo exist copy attachments info
    const { syncedFile, syncedAttachmentsInfo } =
      this.app.attachments.uploadedFileInfo;
    if (syncedFile !== null && syncedAttachmentsInfo !== null) {
      await this.app.attachments.sendFile(
        "thread",
        syncedDoc["thread.omfNumber"],
        customer
      );
    }

    //if pcnDocument exist copy attachments info and delete the pcnDocument
    if (this.app.pcn.pcn != null && this.app.pcn.id != null) {
      await this.app.pcn.syncPcnDocument(result._id);
    }

    //Customer customizations after saving
    if (this.app.customers.expectCurrent === Customer.NS) {
      //enable/disable next/previous status button for NS
      this.app.stepper.isStepDisabled(
        this.threadDoc.omfStatus,
        this.app.post.solutions
      );
    }

    //set thread to fav for NS/MRCE/BVG/RHEINMETALLAIRDEFENCE responsibles
    if (
      this.app.customers.expectCurrent === Customer.NS ||
      this.app.customers.expectCurrent === Customer.MRCE ||
      this.app.customers.expectCurrent === Customer.BVG ||
      this.app.customers.expectCurrent === Customer.RHEINMETALLAIRDEFENCE
    ) {
      this.setThreadToFav(this.threadDoc, result._id);
    }

    //generate impacts
    if (this.app.state.partsToVehicles.length !== 0) {
      if (this.threadDoc.omfNumber === null) {
        return;
      }

      await this.checkCPNExist(this.thread[this.app.fieldId.thread.artNumber]);
      if (this.cpnExist) {
        await this.app.impact.generateImpactsFromParts(
          this.threadDoc.omfNumber
        );
        sessionStorage.setItem("fromTree", "");
      }
    }

    this.app.state.next({
      unlockedId: null,
    });
    this.app.treeRow.currentRow = {} as Row;
    // this.app.field.displayCassesPerCpn = false;
    // this.app.field.displayCassesPerMpn = false;
    this.disabledSaveButton = false;
    setTimeout(() => {
      this.app.state.next({ hasError: false, errorText: "" });
    }, 3000);

    if (sessionStorage.manufacturer != null) {
      sessionStorage.removeItem("manufacturer");
    }

    if (newThread) {
      this.app.leftNav.selectedBox = this.app.listId.thread.main;
      //push the newly created id at the biginning of ids array so navigation could work
      this.ids.unshift(result._id);
      //navigate to the thread details page after thread was created
      this.app.routing.navigateThread(result._id);
    }
    await this.checkRMData();
    this.app.field.inputSubject.next(true);
    this.app.spinner.hideSpinner();
  }

  private checkPcnLinkField(thread: Thread) {
    if (
      !StringUtils.isNullOrEmpty(thread["thread.pcnLink"]) &&
      thread["thread.pcnLink"].split(":")[0] !== "http" &&
      thread["thread.pcnLink"].split(":")[0] !== "https"
    ) {
      this.app.state.next({
        hasError: true,
        errorText: this.app.text.thread.pcnLinkFormat,
      });
      setTimeout(() => {
        this.app.state.next({ hasError: false, errorText: "" });
      }, 3000);
      return false;
    }
    return true;
  }

  private getStatusChanges() {
    let isPrevStatusSet: boolean = false;
    let isNextStatusSet: boolean = false;
    const omfStatuses =
      this.app.field.getFieldSettings("thread.omfStatus").options;

    if (omfStatuses != null) {
      const omfStatusesKeys = Object.keys(omfStatuses);
      const cleanOMFStatusIndex = omfStatusesKeys.indexOf(
        this.cleanModel[this.app.fieldId.thread.omfStatus]
      );
      const currentOMFStatusIndex = omfStatusesKeys.indexOf(
        this.threadDoc.omfStatus
      );

      if (cleanOMFStatusIndex > currentOMFStatusIndex) {
        isPrevStatusSet = true;
      }

      if (currentOMFStatusIndex - cleanOMFStatusIndex >= 1) {
        isNextStatusSet = true;
      }
    }
    return {
      isPrevStatusSet: isPrevStatusSet,
      isNextStatusSet: isNextStatusSet,
    };
  }

  async addCaseToFav(
    users: string[],
    threadId: string,
    toggle: boolean
  ): Promise<void> {
    // Save updated profiles
    //Do not add the case to fav for team role, NS client
    if (this.app.customers.expectCurrent === Customer.NS) {
      users.forEach((user) => {
        let userFound = this.app.users.userRoles.find(
          (u: UserOptions) => u.name === user
        );
        if (userFound != null) {
          let userRoles = this.app.users.getUserRoles(user);
          if (userRoles.includes("team")) {
            let index = users.indexOf(user);
            users.splice(index, 1);
          }
        }
      });
    }
    await this.app.profile.updateMultipleFavorite(
      users,
      this.app.customers.expectCurrent,
      threadId,
      toggle
    );
  }

  async generateNewOmfNumber(customer: string) {
    let newOmfNumber: string = "";
    const fieldId = this.app.fieldId.thread.omfNumber;
    //get the client omf number format
    const { format } = await this.getOmfFormat(customer);
    if (format == null) {
      throw new Error("no code format for field " + fieldId);
    }

    //create key for the last 5 omf numbers request
    let key = this.createKey(customer);
    //get the last 5 omf numbers created
    let omf = await lastOmfNumbers(customer, key);
    if (omf.length > 0) {
      newOmfNumber = this.createNewOmfNumber(customer, omf, format);
    } else {
      //generate the first omfNumber oor that day/ year/ database
      const generator = new CodeGenerator(format);
      const date = new Date();
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();
      //set starting value for omfNumber
      let start: number = 1;
      if (this.app.customer === Customer.NS) {
        start = 1000;
      }
      if (this.app.customer === Customer.LRE) {
        start = 6000;
      }
      if (this.app.customer === Customer.MTU) {
        start = 2000;
      }

      for (let number = start; number < 100000; number++) {
        const code: string = generator.generate({
          number,
          year,
          month,
          day,
        });
        newOmfNumber = code;
        return code;
      }
    }
    this.app.state.next({ omfNumber: newOmfNumber });
    if (newOmfNumber !== null) {
      return newOmfNumber;
    } else {
      throw new Error("failed to create a new omf number");
    }
  }

  async changeToNextStatus() {
    this.threadReady = false;
    // model2Doc function will be removed once model won't have "thread."
    this.threadDoc = model2Doc("thread", this.thread);

    // Get the current status index and increse it with +1
    const curentStepIndex = this.app.list.stepper.steps.findIndex(
      (s: string) => s === this.threadDoc.omfStatus
    );
    this.threadDoc.omfStatus = this.app.list.stepper.steps[curentStepIndex + 1];

    // Update thread with the new status
    let result = (await updateThread(
      this.app.customers.expectCurrent,
      this.threadDoc,
      true
    )) as Thread;

    this.threadSubject.next(result);
    this.creditsSubject.next(result);

    this.cleanModel = doc2Model("thread", result);
    this.thread = doc2Model("thread", result); // doc2Model function will be removed once model won't have "thread."
    this.threadReady = true;
  }

  async generateThreads(docs: any[]) {
    this.getAllThreads();
    let threadDocs: ThreadBulk[] = [];
    docs.forEach((doc) => {
      let threadDoc = {} as ThreadBulk;
      let existingDoc = this.checkIfExists(doc);
      if (existingDoc !== undefined) {
        threadDoc = this.updateFieldsFromImport(doc, existingDoc);
      } else {
        this.app.import.props.forEach((field) => {
          if (doc[field.key] !== null && doc[field.key] !== undefined) {
            threadDoc[field.key] = doc[field.key].toString();
            if (
              field.key === "dinCodeRisk" ||
              field.key === "changeClasses" ||
              field.key === "creator_class" ||
              field.key === "priority" ||
              field.key === "originOfNotification" ||
              field.key === "productCategory"
            ) {
              this.app.field.handleSpecialLogic(field.key, doc[field.key]);
              threadDoc[field.key] = this.app.field.handleSpecialLogic(
                field.key,
                doc[field.key]
              );
            }
          } else {
            threadDoc[field.key] = "";
          }
        });
      }

      threadDoc.type = "thread";
      threadDoc = setCreateTimeAndUpdateTime(threadDoc);
      threadDoc.user_id = this.app.user != null ? this.app.user : "";
      threadDoc.update_user = threadDoc.user_id;

      if (threadDoc._id === null || threadDoc._id === "") {
        delete threadDoc._id;
      }

      threadDocs.push(threadDoc);
    });
    docs = threadDocs;
    this.app.import.docs = docs;
    (docs as []).forEach((doc: Thread) => {
      this.app.import.selected.add(doc.omfNumber);
    });

    this.app.import.stepper = "reviewList";
    this.app.import.step = "third";
  }

  async getAffectedItems(omfNumber: string) {
    let vehicles: string[] = [];

    const impacts: Impact[] = await getImpacts(
      this.app.customers.expectCurrent,
      omfNumber
    );
    impacts.forEach((impact: Impact) => {
      if (impact.omfVehicleName != null) {
        vehicles.push(impact.omfVehicleName);
      }
    });
    return vehicles;
  }

  setDefaultDueDate(value: string) {
    const now = toDateString(new Date());
    let result: string = "";

    switch (value) {
      case "high":
        result = addDays(now, 7);
        break;
      case "medium":
        result = addDays(now, 14);
        break;
      case "low":
        result = addDays(now, 21);
        break;
    }

    return result;
  }

  async setCommodityIdOnThread(): Promise<void> {
    /** forced the list of commodityIds from SAP Data, to the thread.export -> only for DB */
    let items: any = await getDocsByType(
      this.app.customers.expectCurrent,
      "internalItem"
    );
    this.threads.forEach((thread: Thread) => {
      let matchedItem: string[] = items
        .filter((item: InternalItem) => item.omfNumber === thread.omfNumber)
        .map((i: InternalItem) => i.omfCommodityId);
      if (matchedItem) {
        thread["omfCommodityId"] = matchedItem as any;
      }
    });
  }
  async navigateThreadByOmfNumber(omfNumber: string) {
    let thread: any = {} as Thread;
    const customer = this.app.customers.expectCurrent;
    thread = await getDocsByOmfNumber(customer, "thread", omfNumber);
    if (thread !== undefined) {
      this.app.routing.navigateThread(thread[0]._id);
    }
  }

  async deleteThread() {
    const customer = this.app.customers.expectCurrent;
    let doc = model2Doc("thread", this.app.thread.thread) as any;
    await deleteThread(customer, doc);

    this.app.thread.id = null;
    this.app.routing.navigateHome();
  }

  async exportZip(): Promise<void> {
    // model2Doc function will be removed once model won't have "thread."
    this.threadDoc = model2Doc("thread", this.thread);

    const customer = this.app.customers.current;
    const ZipName = this.thread["thread.pcnID"]
      ? this.thread["thread.pcnID"]
      : this.thread["thread.omfNumber"];
    const pcnbodyXml = getDataXml(
      this.threadDoc,
      [this.threadDoc],
      this.app.language
    ).replace(/^\s*[\r\n]/gm, "");
    const zip = new JSZip();
    zip.file("PCNbody.xml", pcnbodyXml);

    if (this.thread["thread._id"] == null) {
      throw new Error("id is null");
    }
    // attachments
    const attachments = await this.app.file.getAttachmentsByDocType(
      this.thread["thread._id"],
      "thread",
      customer
    );
    const attachedFiles = [];
    if (Object.keys(attachments).length > 0 && attachments.length !== 0) {
      for (const fileName of Object.keys(attachments)) {
        const fileLink = [
          customer,
          "thread",
          this.thread["thread._id"],
          fileName,
        ].join("/");
        const content = await downloadFile(fileLink);
        const attachmentFile = {
          name: fileName,
          length: content.size,
        };
        attachedFiles.push(attachmentFile);
        zip.file(`Attachments/${fileName}`, content);
      }
    }
    const attachmentsXml = attachmentsXmlTemplate(attachedFiles);
    zip.file("Attachments.xml", attachmentsXml);
    const content = await zip.generateAsync({
      type: "blob",
    });
    saveAs(content, `${ZipName}.zip`);
  }

  setNewBoxesLeft() {
    if (this.app.list.thread.boxesLeftCreateCase.length > 0) {
      this.boxesLeftCreateCase = this.app.list.thread.boxesLeftCreateCase;
    } else {
      let boxes = Array.from(this.app.list.thread.boxesLeftEditMode);
      if (sessionStorage.getItem("fromTree") !== null) {
        if (boxes.findIndex((box) => box === "thread.impacts") === -1) {
          boxes.push("thread.impacts");
        }
      }
      this.boxesLeftCreateCase = boxes;
    }
  }

  getAlertLevel(dueDate: string, time: number): AlertLevel | undefined {
    try {
      if (dueDate != null && dueDate !== "") {
        const milliseconds = Date.parse(dueDate);
        const MILLISECONDS_PER_DAY: number = 1000 * 60 * 60 * 24;
        const daysLeft = Math.round(
          (milliseconds - time) / MILLISECONDS_PER_DAY
        );
        if (daysLeft > 145) {
          return "low";
        } else if (daysLeft > 60) {
          return "medium";
        } else if (daysLeft >= 0) {
          return "high";
        } else if (daysLeft < 0) {
          return "very-high";
        }
      }
    } catch (err) {
      //NOP
    }
  }

  getAlertLevelColor(level: AlertLevel): string | undefined {
    try {
      if (level !== undefined) {
        if (level === "low") {
          return "#008000";
        } else if (level === "medium") {
          return "#ffcc00";
        } else if (level === "high") {
          return "orange";
        } else if (level === "very-high") {
          return "red";
        }
      }
    } catch (err) {
      //NOP
    }
  }

  resetSeletectBox() {
    this.app.type = "thread";
    if (
      this.app.unlockedId !== null &&
      this.app.leftNav.selectedBox != null &&
      !this.app.list.thread.boxesLeftEditMode.includes(
        this.app.leftNav.selectedBox
      ) &&
      !this.app.thread.isNew
    ) {
      this.app.leftNav.selectedBox = this.app.listId.thread.main;
    }
  }

  hasEditPermission() {
    const { permission, user } = this.app;
    if (permission.thread.edit) {
      this.hasPermissionToEdit = true;
    } else if (
      permission.thread.editOwn &&
      this.thread["thread.user_id"] === user
    ) {
      this.hasPermissionToEdit = true;
    } else {
      this.hasPermissionToEdit = false;
    }
  }

  hasPermissionToDelete() {
    if (this.app.customers.expectCurrent === Customer.SCHIEBEL) {
      if (
        this.thread["thread.user_id"] === this.app.user ||
        this.app.auth.rolesArray.includes(`${Customer.SCHIEBEL}-admin`)
      ) {
        this.hasDeletePermission = true;
      } else {
        this.hasDeletePermission = false;
      }
    } else {
      this.hasDeletePermission = this.hasPermissionToEdit;
    }
    return this.hasDeletePermission;
  }

  hasDeleteImagePermission(): void {
    const thread = model2Doc("thread", this.app.thread.thread);
    const imageExists = this.app.file.exists(thread);
    const hasBildImage = this.hasBildImage();

    if (
      (imageExists ||
        hasBildImage ||
        !StringUtils.isNullOrEmpty(
          this.app.thread.thread["thread.externalImageSource"]
        )) &&
      this.hasPermissionToEdit
    ) {
      this.hasPermissionToDeleteImage = true;
    } else {
      this.hasPermissionToDeleteImage = false;
    }
  }

  isFav(id = this.thread["thread._id"]) {
    if (id == null) {
      throw new Error("id is null");
    }
    const profile = this.app.profile.ownProfile;
    let thread: any = {} as Thread;
    if (this.app.view === "home") {
      thread = this.app.thread.threadsCompressed.find((t) => t._id === id);
    } else {
      thread = model2Doc("thread", this.thread);
    }
    if (Object.keys(thread).length === 0) {
      return false;
    }
    if (
      profile != null &&
      profile.favorite !== undefined &&
      profile.favorite[this.app.customers.expectCurrent]
    ) {
      let id = thread._id != null ? thread._id : thread.doc._id;
      let isFavorite =
        profile.favorite[this.app.customers.expectCurrent].includes(id);

      if (
        !isFavorite &&
        Object.keys(profile.favorite).indexOf(Customer.OMP) !== -1
      ) {
        isFavorite = profile.favorite.omp.includes(thread._id);
      }
      thread.fav = isFavorite.toString();
      this.isFavorite = isFavorite;
      return isFavorite;
    }

    return false;
  }

  async setFav(id = this.thread["thread._id"], fav = !this.isFav(id)) {
    // temporary until we have the class of THREAD in the shared component
    let thread: any = {} as Thread;
    if (this.app.view === "home") {
      thread = this.threadsCompressed.find((t) => t._id === id);
    } else {
      this.isFavorite = fav;
      thread = model2Doc("thread", this.thread);
    }
    if (thread != null) {
      thread.fav = fav.toString();
      const user = this.app.user;
      const customer = this.app.customers.expectCurrent;
      if (user == null) {
        return;
      }
      if (customer == null) {
        return;
      }
      await this.app.profile.updateMultipleFavorite(
        [user],
        customer,
        thread._id,
        true
      );
      this.app.profile.ownProfile = await this.app.profile.getProfile(user);
      this.favSubject.next(thread._id);
      if (this.app.view === "home" && !fav) {
        this.app.filterFields.createFilterObject();
      }
    }
  }

  async setDinCode(dinCode: string) {
    const customer = this.app.customers.expectCurrent;
    this.thread["thread.dinCode"] = dinCode;
    this.thread["thread.dinText"] = dinCode;

    if (
      dinCode !== "" &&
      this.app.customers.getCustomerType() === TRANSPORT_CLIENT
    ) {
      const dinCodeDocument: DinCodeResponsible = await getDinCodeDocument(
        customer,
        dinCode
      );

      if (dinCodeDocument != null) {
        this.app.dinCodeResponsible.set(dinCodeDocument);
        this.setDinCodeRisk(dinCodeDocument);
      }
    }

    if (
      customer !== Customer.RHEINBAHN &&
      dinCode !== "" &&
      this.app.customers.getCustomerType() === TRANSPORT_CLIENT
    ) {
      const commodityGroupDocument: CommodityGroupResponsible =
        await getCommodityGroupDocument(customer, dinCode);
      if (commodityGroupDocument != null) {
        this.app.commodityGroupResponsible.set(commodityGroupDocument);
      }
    }
  }

  async setCommodityResponsible(omfCommodityId: string) {
    const customer = this.app.customers.expectCurrent;
    this.thread["thread.omfCommodityId"] = omfCommodityId;

    if (
      omfCommodityId !== "" &&
      this.app.customers.getCustomerType() === TRANSPORT_CLIENT
    ) {
      const commodityGroupDocument: CommodityGroupResponsible =
        await getCommodityGroupDocument(customer, omfCommodityId);
      if (commodityGroupDocument != null) {
        this.app.commodityGroupResponsible.set(commodityGroupDocument);
      }
    }
  }

  /** This function is currently used only by mrce client */
  setUsedIn(commodityClass: string) {
    if (!commodityClass) {
      this.app.thread.thread["thread.usedIn"] = null;
    } else {
      this.app.thread.thread["thread.commodityClass"] = commodityClass;
      Object.keys(commodityMap).forEach((key) => {
        if (key === commodityClass) {
          this.app.thread.thread["thread.usedIn"] = commodityMap[key];
        }
      });
    }
  }

  copyDocToThread(document: Thread, syncedFromCustomer?: string) {
    const threadKeys = Object.keys(document);
    threadKeys.forEach((key) => {
      const type = key.split(".")[0];
      key = key.split(".")[1];

      if (
        key === "_id" ||
        key === "_rev" ||
        (key === "omfNumber" && syncedFromCustomer !== Customer.ABC) ||
        key === "omfStatus" ||
        key === "reporterName" ||
        key === "projectStatus" ||
        key === "applicationArea" ||
        key === "create_time" ||
        key === "user_id" ||
        key === "update_time" ||
        key === "update_user" ||
        key === "_attachments" ||
        key === "vehicleNames" ||
        key === "supplyManagerName" ||
        (key === "postCount" && this.app.thread.isCaseDuplicated === true)
      ) {
        return;
      }
      if (!StringUtils.isNullOrEmpty(document[`${type}.${key}`])) {
        this.thread[`thread.${key}`] = document[`${type}.${key}`];
      }
    });
  }

  copyRmFieldsToThread(
    document: Manufacturer | AlertData | SeMatch,
    mappingConst: { [key: string]: string | null }
  ) {
    if (document == null) {
      return;
    }
    const keys = Object.keys(document);
    keys.forEach((key) => {
      if (mappingConst[key] != null) {
        this.thread[`thread.${mappingConst[key]}`] = document[key];
        if (key === "issueDate") {
          this.thread[`thread.${mappingConst[key]}`] = formatDate(
            document[key]
          );
        }
      }
    });

    if (document.type === "manufacturer" || document.alertType === "alert") {
      this.thread["thread.changeClasses"] = ["PDN"];
      this.thread["thread.creator_class"] = "PDN";
    }

    if (
      document.type === "seMatch" &&
      (document.Lifecycle === "obsolete" || document.Lifecycle === "PDN issued")
    ) {
      this.thread["thread.changeClasses"] = ["PDN"];
    }
    if (document.alertType === "changeAlert") {
      this.thread["thread.creator_class"] = "PCN";
    }
    this.app.type = null;
  }

  duplicateCase() {
    this.isCaseDuplicated = true;
    this.app.routing.navigateNewThread(this.app.customers.expectCurrent);
  }

  //PRIVATE FUNCTIONS
  //check if case is in edit mode and do not generate a new id if it is
  private async setCaseId() {
    let id: string;
    if (this.app.thread.isNew) {
      //if client can choose omf number do checks else generate a new omf number
      if (this.app.permission.thread.chooseCaseNumber) {
        if (await this.omfCheck(this.thread["thread.omfNumber"])) {
          id = this.thread["thread.omfNumber"];
        } else {
          return null;
        }
      } else {
        id = await this.generateNewOmfNumber(this.app.customers.expectCurrent);
      }
    } else {
      id = this.thread["thread.omfNumber"];
    }
    return id;
  }

  private async omfCheck(omfNumber: string) {
    //get all omfnumbers for the clients wich have the option to manually choose the omfNumber
    let omfNumbers = await allOmfNumbers(this.app.customers.expectCurrent, [
      omfNumber,
    ]);
    //if thread is in edit mode and it is already in database do not make any other checks
    if (!this.app.thread.isNew) {
      if (omfNumbers.includes(omfNumber)) {
        return true;
      }
    }
    if (omfNumbers.length > 0) {
      this.app.state.next({
        hasError: true,
        errorText: this.app.text.thread.caseNumberExist,
      });
      setTimeout(() => {
        this.app.state.next({ hasError: false, errorText: "" });
      }, 3000);
      return false;
    } else {
      return this.checkOmfNumberFormat(omfNumber);
    }
  }

  private checkOmfNumberFormat(omfNumber: string) {
    const fieldSettings = this.app.field.getFieldSettings("thread.omfNumber");
    if (fieldSettings.format && fieldSettings.format.regex) {
      let clientRegex = new RegExp(fieldSettings.format.regex);
      if (clientRegex.test(omfNumber)) {
        return true;
      } else {
        this.app.state.next({
          hasError: true,
          errorText: this.app.text.thread.invalidCaseNumberFormat,
        });
        setTimeout(() => {
          this.app.state.next({ hasError: false, errorText: "" });
        }, 3000);
        return;
      }
    }
  }

  private async getOmfFormat(customer: string) {
    let settings = await getCustomerSettings(customer);
    return settings.thread.field.omfNumber;
  }

  private createKey(customer: string) {
    let key = undefined;
    let date = new Date();
    switch (customer) {
      case Customer.DB:
        key = `${date.getFullYear().toString()}`;
        break;
      case Customer.BVG:
        key = `${date.getFullYear().toString().substring(2)}`;
        break;
      case Customer.KOMAX:
      case Customer.TSK:
      case Customer.KNDS:
        key = `${date.getFullYear()}`;
        break;
    }
    return key;
  }

  private createNewOmfNumber(customer: string, omf: OmfFormat[], format: any) {
    let sortedOmf: OmfFormat[];
    let highestOmf: OmfFormat;
    let highestOmfNumber: number;
    let highestOmfPrefix: string = "";
    let newNumber: string;
    let newOmfNumber: string = "";
    //for schiebel, ceotronics and cae we do not have any prefix in omfNumber
    if (
      customer !== Customer.SCHIEBEL &&
      customer !== Customer.CEOTRONICS &&
      customer !== Customer.CAE
    ) {
      sortedOmf = omf.sort((a: any, b: any) =>
        Number(a.number) > Number(b.number)
          ? -1
          : Number(b.number) > Number(a.number)
          ? 1
          : 0
      );
      highestOmf = sortedOmf[0];
      highestOmfNumber = Number(highestOmf.number);
      highestOmfPrefix = highestOmf.prefix;
    } else {
      sortedOmf = omf.sort((a: any, b: any) =>
        Number(a) > Number(b) ? -1 : Number(b) > Number(a) ? 1 : 0
      );
      highestOmf = sortedOmf[0];
      highestOmfNumber = Number(highestOmf);
    }
    newNumber = (highestOmfNumber + 1).toString();
    if (format.values.number.digits != null) {
      let newOmf: string =
        highestOmfPrefix +
        "0".repeat(format.values.number.digits - newNumber.length) +
        newNumber;
      newOmfNumber = newOmf;
    } else if (format.values) {
      //for schiebel and ceotronics
      newOmfNumber = newNumber;
    } else {
      throw new Error("failed to create a new thread id");
    }
    return newOmfNumber;
  }

  private setWarehouseRangesInfo(thread: Thread) {
    //use Math.abs for old cases on which actualStock, averageCons and averageConsStochastic was saved as string
    if (thread.actualStock != null) {
      thread.actualStock = Math.abs(thread.actualStock);

      if (thread.averageCons != null) {
        thread.averageCons = Math.abs(thread.averageCons);
      }
      if (thread.averageConsStochastic != null) {
        thread.averageConsStochastic = Math.abs(thread.averageConsStochastic);
      }

      //annual calculation
      let stockRangeCons: number = 0;
      let stockRangeRequired: number = 0;
      let stockRangeCR: number = 0;

      if (thread.averageConsStochastic != null && thread.actualStock != null) {
        //stokRangeCons
        stockRangeCons =
          (365 / thread.averageConsStochastic) * thread.actualStock;
        thread.stockRangeCons = Math.round(stockRangeCons);
      }

      if (thread.averageCons != null && thread.actualStock != null) {
        // stockRangeRequired
        stockRangeRequired = (365 / thread.averageCons) * thread.actualStock;
        thread.stockRangeRequired = Math.round(stockRangeRequired);
      }

      if (
        thread.averageCons != null &&
        thread.averageConsStochastic != null &&
        thread.actualStock != null
      ) {
        //totalConsumation, stockRangeCR
        stockRangeCR =
          (365 / (thread.averageCons + thread.averageConsStochastic)) *
          thread.actualStock;
        thread.totalConsumation =
          thread.averageCons + thread.averageConsStochastic;
        thread.stockRangeCR = Math.round(stockRangeCR);
      }

      //closingDateAfterCons, closingDateRequired, closingDateCR
      if (
        thread.actualStockDate !== "" &&
        thread.actualStockDate !== undefined
      ) {
        const milisecondsInDay = 86400000;
        const closingDateAfterCons =
          new Date(thread.actualStockDate).getTime() +
          stockRangeCons * milisecondsInDay;
        const closingDateRequired =
          new Date(thread.actualStockDate).getTime() +
          stockRangeRequired * milisecondsInDay;
        const closingDateCR =
          new Date(thread.actualStockDate).getTime() +
          stockRangeCR * milisecondsInDay;
        thread.closingDateAfterCons = formatDate(
          new Date(closingDateAfterCons).getTime()
        );
        thread.closingDateRequired = formatDate(
          new Date(closingDateRequired).getTime()
        );
        thread.closingDateCR = formatDate(new Date(closingDateCR).getTime());
      }
    }
  }

  private async setThreadToFav(threadDoc: Thread, threadId: string) {
    let respArray: string[] = [];
    //persons from contact data which will have the thread to favs
    let threadResponsibles: any[] = [];
    if (this.app.customers.expectCurrent === Customer.NS) {
      if (threadDoc.otherStakeholder) {
        threadDoc.otherStakeholder.forEach((value: string) => {
          threadResponsibles.push(value);
        });
      }

      let trainResponsible = threadDoc.supplyManagerName;

      threadResponsibles = [
        ...threadResponsibles,
        threadDoc.dinCodeRespName,
        trainResponsible,
        threadDoc.buyerName,
        threadDoc.sysEngineerName,
      ];
    }
    if (this.app.customers.expectCurrent === Customer.MRCE) {
      threadResponsibles = threadDoc.stakeholder;
    }
    if (this.app.customers.expectCurrent === Customer.BVG) {
      threadResponsibles = [threadDoc.teamMemberName];
    }
    if (this.app.customers.expectCurrent === Customer.RHEINMETALLAIRDEFENCE) {
      threadResponsibles = [
        ...threadResponsibles,
        threadDoc.dinCodeRespName,
        threadDoc.buyer,
        threadDoc.engineerName,
      ];
    }
    let uniqueResponsibles = [...new Set(threadResponsibles)];
    //create the responsibles array
    uniqueResponsibles.forEach((resp) => {
      if (!StringUtils.isNullOrEmpty(resp)) {
        respArray.push(resp);
      }
    });

    if (respArray && respArray.length > 0) {
      await this.addCaseToFav(respArray, threadId, false);
    }
  }

  private checkIfExists(doc: Thread) {
    if (this.threads) {
      let thread = this.threads.find(
        (thread: Thread) => thread.omfNumber === doc.omfNumber
      );
      return thread;
    }
  }

  private updateFieldsFromImport(doc: Thread, existingDoc: ThreadBulk) {
    Object.keys(doc).forEach((key) => {
      existingDoc[key] = (doc as any)[key];
    });
    return existingDoc;
  }

  private hasBildImage() {
    if (
      this.app.thread.thread["thread._attachments"] != null &&
      Object.keys(this.app.thread.thread["thread._attachments"] > 0)
    ) {
      const hasBildImage =
        this.app.thread.thread["thread._attachments"][BILD_IMAGE];
      if (hasBildImage) {
        return true;
      } else {
        return false;
      }
    }
  }

  private setDinCodeRisk(doc: DinCodeResponsible) {
    let risk: string[] = [];
    if (doc.riskBK) {
      risk.push("BK");
    }
    if (doc.riskIO) {
      risk.push("IO");
    }
    if (doc.riskSK) {
      risk.push("SK");
    }
    if (doc.riskUE) {
      risk.push("UE");
    }
    this.thread["thread.dinCodeRisk"] = risk;
  }

  async getCasesCompressed() {
    let docs = await getThreadsCompressed(
      this.app.customers.expectCurrent,
      "thread"
    );
    this.app.thread.threadsCompressed = docs;
    let omfNumbers = docs.map((thread: any) => {
      return {
        omfNumber: thread.omfNumber,
        id: thread._id,
      };
    });
    this.findCaseDuplicates(omfNumbers);
    this.ids = docs.map((doc) => doc._id);
    this.app.paginator.getPage(this.ids);
    this.app.docs.docsSubject.next(true);
    return this.app.thread.threadsCompressed;
  }

  findCaseDuplicates(omfNumbers: any[]) {
    let unique = new Map<string, string>();
    let duplicates = new Map<string, string>();
    omfNumbers.forEach((o: any) => {
      if (!unique.has(o.omfNumber)) {
        unique.set(o.omfNumber, o.id);
      } else {
        duplicates.set(o.omfNumber, o.id);
      }
    });
    console.log("DUPLICATES", duplicates);
  }
  async getCasesByPartNumbers() {
    let docs = await getThreadsbyPartNumbers(
      this.app.customers.expectCurrent,
      "thread"
    );
    this.threadsByPartNumbers = docs;
    return docs;
  }

  async getCasesByCPN(cpn?: string, mpn?: string, pcnId?: string) {
    let filterProperty: string = "";
    let field: string = "";
    if (cpn !== "" && cpn !== undefined) {
      filterProperty = cpn;
      field = "artNumber";
    }
    if (mpn !== "" && mpn !== undefined) {
      filterProperty = mpn;
      field = "crtNumber";
    }

    if (pcnId !== "" && pcnId !== undefined) {
      filterProperty = pcnId;
      field = "pcnID";
    }
    let existingDocs = this.threadsByPartNumbers
      .filter(
        (thread: Partial<Thread>) =>
          thread[field] && thread[field] === filterProperty
      )
      .map((t: Partial<Thread>) => {
        return {
          omfNumber: t.omfNumber,
          id: t._id,
          cpn: t.artNumber,
          mpn: t.crtNumber,
          pcnId: t.pcnID,
        };
      });

    existingDocs = existingDocs.filter(
      (e) => e.omfNumber !== this.app.thread.id
    );

    if (filterProperty === cpn && !StringUtils.isNullOrEmpty(cpn)) {
      this.app.thread.existingCasesPerCpn = existingDocs;
      this.app.field.displayCassesPerCpn = true;
    } else if (filterProperty === mpn && !StringUtils.isNullOrEmpty(mpn)) {
      this.app.thread.existingCasesPerMpn = existingDocs;
      this.app.field.displayCassesPerMpn = true;
    } else if (filterProperty === pcnId) {
      this.app.thread.existingCasesPerPcnId = existingDocs;
      this.app.field.displayCassesPerPcnId = true;
    }
  }

  getDueDateStyle(doc: Thread) {
    // closingDate is displayed on home table view for NS client and need to have the same coloring as the dueDate
    if (
      this.app.customers.expectCurrent === Customer.NS &&
      doc.closingDate !== null
    ) {
      doc.dueDate = doc.closingDate;
    }
    if (doc.dueDate) {
      const time = new Date().getTime();
      const alertLevel = this.app.thread.getAlertLevel(
        doc.dueDate.toString(),
        time
      );
      if (
        doc.omfStatus != "Geschlossen" &&
        alertLevel != null &&
        alertLevel !== undefined
      ) {
        return {
          color: this.app.thread.getAlertLevelColor(alertLevel),
        };
      } else {
        return { color: "black" };
      }
    } else {
      return { color: "black" };
    }
  }
  getValueOfTheField(fieldId: string, doc: Thread) {
    switch (fieldId) {
      case "omfVehicleName":
      case "vehicleGroup":
      case "omfVehicleClass":
        let impacts = this.app.impacts.impacts
          .filter(
            (impact) =>
              impact.omfVehicleName && impact.omfNumber === doc.omfNumber
          )
          .map((impact) => {
            const impactProperty = "impact." + fieldId;
            return this.app.field.getFieldValueAsText(
              impactProperty,
              impact[fieldId]
            );
          })
          .sort()
          .join(", ");
        return impacts;
      case "dlzDays":
        if (doc["closingDate"] !== undefined && doc["oDate"] !== undefined) {
          const millisecondsPerDay = 1000 * 60 * 60 * 24;
          const oDate = Date.parse(doc["oDate"]);
          const closingDate = Date.parse(doc["closingDate"].toString());
          if (isNaN(oDate) || isNaN(closingDate)) {
            return "";
          }
          return ((closingDate - oDate) / millisecondsPerDay).toString();
        }
        return "";
      case "omfStatus":
      case "creator_class":
      case "itemType":
      case "obsolescenceStatus":
      case "statusRisk":
        return this.app.field.getOptionText("thread." + fieldId, doc[fieldId]);
      case "dinText":
      case "dinCode":
      case "dinCodeRisk":
      case "productCategory":
      case "priority":
      case "originOfNotification":
      case "unavailabilityRisk":
      case "repairable":
      case "omApproach":
        return this.app.field.getFieldValueAsText(
          "thread." + fieldId,
          doc[fieldId]
        );
      case "changeClasses":
        let values: string[] = [];

        if (doc[fieldId] != null) {
          /**needed for the portal cases because the old cases have the changeClass as string and currently is thread.changeClasses = string[] */
          if (typeof doc[fieldId] === "string") {
            const value = doc[fieldId].toString();
            doc[fieldId] = [];
            doc[fieldId].push(value);
          }
          doc[fieldId].forEach((option) => {
            if (option !== "") {
              values.push(
                this.app.field.getOptionText("thread." + fieldId, option)
              );
            }
          });
        }
        return values.join(",");
      case "items":
      case "vehicleNames":
        let items = new Set<string>();

        let itemNames = doc && doc[fieldId] != null ? doc[fieldId] : [];
        if (itemNames) {
          itemNames.forEach((i: string) => {
            if (i == null || i === "") {
              return;
            }
            items.add(i);
          });
          return Array.from(items).join(",");
        } else {
          return [];
        }
      case "businessArea":
      case "usedIn":
        let options: string[] = [];
        let value: any;
        value =
          doc[fieldId] != null && doc[fieldId].length !== 0 ? doc[fieldId] : [];
        if (value && typeof value !== "string") {
          value.forEach((option: any) => {
            if (
              option !== "" &&
              this.app.field.getOptions("thread." + fieldId).includes(option)
            ) {
              options.push(
                this.app.field.getOptionText("thread." + fieldId, option)
              );
            } else {
              return "";
            }
          });
        } else {
          options.push(
            this.app.field.getOptionText("thread." + fieldId, value)
          );
        }
        return options.join(",");
      case "create_time":
      case "update_time":
      case "closedOn":
      case "itemEOSR":
      case "itemLTD":
      case "endOfProduction":
      case "itemEOS":
      case "dueDate":
      case "closingDate":
      case "oDate":
      case "itemEOP":
      case "entryDate":
        if (!StringUtils.isNullOrEmpty(doc[fieldId])) {
          let date = new Date(formatDatetime(doc[fieldId]));
          return date;
        } else {
          return "";
        }
      // case "closedOn":
      // case "update_time":
      //   if (doc[fieldId] != null) {
      //     return new Date(formatDatetime(doc[fieldId]));
      //   } else {
      //     return "";
      //   }
      case "repairable":
        if (doc["repairable"]) {
          return "yes";
        } else {
          return "no";
        }
      case "resolveClass":
        let acceptedSolutions: any[] = [];
        let finalSolutions = this.app.post.acceptedSCompressed.filter(
          (s) => s.key === doc.omfNumber
        );
        if (finalSolutions.length !== 0) {
          finalSolutions.forEach((s) => {
            let solution: string = "";

            solution = this.app.field.getOptionText(
              "post." + fieldId,
              s.value.resolveClass
            );

            acceptedSolutions.push(solution);
          });
          return acceptedSolutions.join(";");
        } else {
          return "";
        }
      case "resolveCost":
        let costs: number[] = [];
        let markedSolutions = this.app.post.acceptedSCompressed.filter(
          (s) => s.key === doc.omfNumber
        );
        if (markedSolutions.length !== 0) {
          markedSolutions.forEach((s) => {
            let cost: number = s.value.resolveCost;
            costs.push(cost);
          });
          return costs.join(";");
        } else {
          return "";
        }
      case "omfCommodityId":
        let ids: any;
        let commodityIds = new Set<string>();
        if (this.app.customers.expectCurrent === Customer.DB) {
          ids = doc && doc[fieldId] != null ? doc[fieldId] : [];
          if (ids) {
            ids.forEach((option: any) => {
              if (option == null || option === "") {
                return;
              }
              commodityIds.add(option);
            });
            return Array.from(commodityIds).join(",");
          }
        } else {
          return doc[fieldId];
        }
      case "_id":
        // Id is used in this case to export the link of the case - only for DB client
        let caseUrl = "";
        const url = window.location.href.split("home");

        if (url[0] != null) {
          caseUrl = url[0] + "thread/" + doc._id;
        }

        return caseUrl;
      default:
        return (doc as Thread)[fieldId];
    }
  }

  async getExternalCases() {
    let externalCases = await getExternalCases(
      this.app.customers.expectCurrent,
      this.app.filterList.showExternalCases,
      "thread"
    );
    this.app.thread.ids = externalCases.map((t) => t._id);
    this.app.thread.threadsCompressed = externalCases;
    this.app.docs.docsSubject.next(true);
  }

  setStatusBasedOnCustomer() {
    let customer = this.app.customers.expectCurrent;
    switch (customer) {
      case Customer.DB:
        return "10";
      case Customer.MAQUETCARDIO:
      case Customer.AUTOFLUG:
      case Customer.KNDS:
        return "Notiz-Fall";
      case Customer.COOP:
        return "advertised";
      case Customer.RHEINMETALLAIRDEFENCE:
        return "openCase";
      default:
        return "vorerfasst";
    }
  }

  setBuyerBasedOnCustomer() {
    let customer = this.app.customers.expectCurrent;
    switch (customer) {
      case Customer.MAQUETCARDIO:
        return "Richard Safarov";
      default:
        return "";
    }
  }

  async prepareDataForDropdown() {
    let artNumberFieldValue =
      this.app.thread.thread[this.app.fieldId.thread.artNumber];
    let crtNumberFieldValue =
      this.app.thread.thread[this.app.fieldId.thread.crtNumber];
    // prepare data only if there is a value in the field && the current value is different than the one from the thread
    if (this.app.unlockedId != null) {
      if (
        this.app.thread.thread[this.app.fieldId.thread.artNumber] !== "" &&
        artNumberFieldValue !=
          this.app.thread.cleanModel[this.app.fieldId.thread.artNumber]
      ) {
        await this.app.manufacturer.prepareData(
          this.app.fieldId.thread.artNumber,
          artNumberFieldValue
        );
      }
      if (
        this.app.thread.thread[this.app.fieldId.thread.crtNumber] !== "" &&
        crtNumberFieldValue !=
          this.app.thread.cleanModel[this.app.fieldId.thread.crtNumber]
      ) {
        await this.app.manufacturer.prepareData(
          this.app.fieldId.thread.crtNumber,
          crtNumberFieldValue
        );
      }
    }
  }

  changeHeadlineColor() {
    let typeOfPost = this.thread["thread.typeOfPost"];
    if (!StringUtils.isNullOrEmpty(typeOfPost)) {
      this.textColor = "lightText";
      this.titleColor = "lightTitle";
      if (typeOfPost === "seek") {
        this.headlineColor = "headline-color-red";
      }
      if (typeOfPost === "offer") {
        this.headlineColor = "headline-color-green";
      }
      return `${this.textColor} ${this.titleColor} ${this.headlineColor}`;
    } else {
      this.headlineColor = "";
      this.textColor = "";
      this.titleColor = "";
    }
  }

  private async checkIfTitleIsDuplicated() {
    if (
      this.isNew ||
      this.thread[this.app.fieldId.thread.title] !=
        this.cleanModel[this.app.fieldId.thread.title]
    ) {
      return await checkIfTitleIsDuplicated(
        this.app.customers.expectCurrent,
        this.thread[this.app.fieldId.thread.title]
      );
    }
  }

  private setDuplicateTitleError() {
    this.app.hasError = true;
    this.app.errorText = this.app.text.thread.duplicateTitle;
    this.disabledSaveButton = false;
    this.app.spinner.hideSpinner();
  }

  getRouterURL(threadId: string) {
    return `/${this.app.customers.expectCurrent}/thread/${threadId}`;
  }

  getCase(doc: Manufacturer | AlertData | SeMatch) {
    let threadIndex = -1;
    if (this.app.thread.threadsCompressed !== undefined) {
      if (doc.type === "alert" || doc.type === "manufacturer") {
        if (doc.alertType === "inventoryMonitoringAlert") {
          threadIndex = this.app.thread.threadsCompressed.findIndex(
            (thread) => thread.artNumber === doc.partNumber
          );
        } else {
          threadIndex = this.app.thread.threadsCompressed.findIndex(
            (thread) => thread.crtNumber === doc.manufacturerPartNumber
          );
        }
        if (
          threadIndex !== -1 &&
          doc.manufacturerPartNumber !== "" &&
          doc.partNumber !== ""
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        threadIndex = this.app.thread.threadsCompressed.findIndex(
          (thread) => thread.crtNumber === doc.PartNumber
        );
        if (threadIndex !== -1 && doc.PartNumber !== "") {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  getCasesByPartNumber(doc: Manufacturer | AlertData | SeMatch) {
    let threads: Partial<Thread>[] = [];

    if (doc.type != undefined) {
      this.app.type = doc.type;
    }
    if (this.app.thread.threadsCompressed !== undefined) {
      /** if doc.type is alert / manufacturer the search has to be done by manufacturerPartNumber */
      if (doc.type === "alert" || doc.type === "manufacturer") {
        if (doc.alertType === "inventoryMonitoringAlert") {
          threads = this.app.thread.threadsCompressed.filter(
            (thread: Partial<Thread>) => thread.artNumber === doc.partNumber
          );
        } else {
          threads = this.app.thread.threadsCompressed.filter(
            (thread: Partial<Thread>) =>
              thread.crtNumber === doc.manufacturerPartNumber
          );
        }
      } else {
        /** if doc.type is SeMatch search has to be done on "PartNumber" since its the identifier of the manufacturer
         * on the match
         */
        threads = this.app.thread.threadsCompressed.filter(
          (thread: Partial<Thread>) => thread.crtNumber === doc.PartNumber
        );
      }
      if (threads.length > 0) {
        this.app.thread.threadsByPartNumber = threads as any;
      }
    }
    /**return the result sorted by omfNumber */
    let sortedData = this.app.thread.threadsByPartNumber.sort(
      (a: any, b: any) => {
        if (a["omfNumber"] < b["omfNumber"]) {
          return -1;
        } else {
          return 1;
        }
      }
    );
    return sortedData;
  }

  newCase(doc: Manufacturer | AlertData) {
    if (doc.type === "manufacturer") {
      this.app.treeRow.currentManufacturer = doc as Manufacturer;
      sessionStorage.setItem("manufacturer", JSON.stringify(doc));
      this.app.filterHeaderTable.resetHeaderFilters();
    } else {
      this.isCreatedFromAlert = true;
      this.app.thread.modalSubject.next("cpnModal");
      this.app.alerts.currentSelected = doc as AlertData;
    }

    // if (doc.manufacturerPartNumber != null && doc.name != null) {
    //   this.app.treeCell.setManufacturerToStorage(
    //     doc.manufacturerPartNumber,
    //     doc.name
    //   );
    // }
    if (doc.partNumber != null) {
      this.app.treeRow.generateImpactsFromParts(doc.partNumber);
    }

    if (doc._id != null) {
      this.app.attachments.getUploadedFileInfo(
        doc._id,
        "manufacturer",
        this.app.customers.current
      );
    }
    //clear all variables that could have fields info about a thread
    this.app.pcn.pcn = null;
    this.app.pcn.id = null;
    this.app.routing.navigateNewThread();
  }

  async checkRMData() {
    this.cpnExist = false;
    this.mpnExist = false;

    // Check CPN data
    let threadCPN = this.thread[this.app.fieldId.thread.artNumber];
    await this.checkCPNExist(threadCPN);

    // Check MPN data
    let threadMPN = this.thread[this.app.fieldId.thread.crtNumber];
    await this.checkMPNExist(threadMPN);
  }

  private async checkCPNExist(partNumber: string) {
    const parts = await getParts(this.app.customers.expectCurrent, [
      partNumber,
    ]);

    if (parts.length > 0) {
      this.cpnExist = true;
    }
  }

  private async checkMPNExist(manufacturerPartNumber: string) {
    const manufacturer = await getManufacturerByMPN(
      this.app.customers.expectCurrent,
      manufacturerPartNumber
    );

    if (manufacturer.length > 0) {
      this.mpnExist = true;
    }
  }
}
