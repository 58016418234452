import { Component, Input } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { getApp } from "../app";
import { Type } from "../../../../shared/components";
import { downloadFile } from "../api.service";
import { saveAs } from "file-saver-es";
import { AttachmentHistoryInfo } from "../../../../shared/models/attachment";
import { StringUtils } from "../../../../shared/utils/string.utils";

export class tagModel {
  [key: string]: string;
}

@Component({
  selector: "app-attachment",
  templateUrl: "./attachment.component.html",
  styleUrls: ["./attachment.component.scss"],
})
export class AttachmentComponent {
  @Input() fileName: string = "";
  @Input() id: string = "";
  @Input() type: Type = "thread";
  @Input() editPermission = true;
  @Input() safetyEvaluationFileNS = false;
  @Input() fileDetails: any = {} as AttachmentHistoryInfo;
  fileLink: string = "";
  tagModel: tagModel = {};
  showTagField: boolean = false;
  tagList = new Set<string>();
  url: SafeUrl = "";
  acceptedFileFormatsForUrls: string[] = [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "gif",
    "pdf",
  ];
  fileExtension: string = "";

  app = getApp((app) => {
    this.app = app;
  });
  tags: string[] = [];

  constructor(private sanitizer: DomSanitizer) {}

  async ngOnInit() {
    this.fileLink = this.createFileLink();
    const fileType = this.getAttachmentType(this.fileLink);
    this.getFileExternsion();
    // Do not create file url for file formats which are not ment to open in a new tab
    // All types of file which are not included in the acceptedFileFormatsForUrls array have to be downloaded, not opened in a new tab
    if (this.acceptedFileFormatsForUrls.includes(fileType)) {
      await this.createFileUrl(this.fileLink);
    }
  }

  createFileLink() {
    const customer = this.app.customers.expectCurrent;
    let link = "";
    //if the attachment comes from a comment or solution set the id of that doc in order to create the file link
    if (
      this.app.attachments.commentAttachments.length > 0 ||
      this.app.attachments.solutionAttachments.length > 0 ||
      this.app.attachments.taskAttachments.length > 0
    ) {
      let postsAttachments = this.app.attachments.commentAttachments.concat(
        this.app.attachments.solutionAttachments,
        this.app.attachments.taskAttachments
      );
      postsAttachments.forEach((attachment) => {
        if (attachment.name === this.fileName) {
          this.id = attachment.id;
        }
      });
    }
    link = [customer, this.type, btoa(this.id), this.fileName].join("/");

    return link;
  }

  getFileExternsion() {
    let lastIndexOfDot = this.fileName.lastIndexOf(".");
    this.fileExtension = this.fileName.substring(lastIndexOfDot);
  }

  private getAttachmentType(fileLink: string) {
    if (fileLink != null) {
      let splitLink = fileLink.split(".");
      return splitLink[splitLink.length - 1];
    }
    return "";
  }

  /** take into consideration that this function could change the extension of the document
   * therefore it has to be checked first
   */
  async createFileUrl(fileLink: string) {
    let fileType: string = "";
    const data = await downloadFile(fileLink);

    const fileExtension = data.type.split("/")[1];
    // Special case for files with svg extension to be able to open these in a new tab
    if (fileExtension === "svg") {
      fileType = "image/svg+xml";
    } else {
      fileType = data.type;
    }

    const blob = new Blob([data], { type: fileType });
    this.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
  }

  async downloadAttachment(fileLink: string) {
    const data = await downloadFile(fileLink);
    const blob = new Blob([data], { type: data.type });

    saveAs(blob, this.fileName);
  }

  getTags() {
    if (
      this.fileDetails != null &&
      this.fileDetails.tags != null &&
      this.fileDetails.tags.length > 0
    ) {
      this.tags = this.fileDetails.tags;
    }
    return this.tags;
  }

  addTag() {
    this.app.attachment.addTag(this.fileDetails, this.tagModel, this.fileLink);
    this.tagModel = { "attachment.tag": "" };
    this.showTagField = false;
  }

  isDisabled() {
    const { tagModel } = this;
    let tag = tagModel["attachment.tag"];
    if (StringUtils.isNullOrEmpty(tag) || tag.replace(/\s/g, "").length == 0) {
      return true;
    }
    return false;
  }

  checkPermission(fileName: string) {
    let postIndex = this.app.attachments.commentAttachments.findIndex(
      (post) => post.name === fileName
    );
    let solIndex = this.app.attachments.solutionAttachments.findIndex(
      (post) => post.name === fileName
    );
    let taskIndex = this.app.attachments.taskAttachments.findIndex(
      (post) => post.name === fileName
    );
    let ocCommentIndex = this.app.attachments.ocCommentAttachments.findIndex(
      (post) => post.name === fileName
    );

    if (
      ((postIndex !== -1 || solIndex !== -1 || taskIndex !== -1) &&
        this.type === "thread") ||
      (!this.app.permission.attachment.addTag &&
        this.fileDetails != null &&
        this.fileDetails.uploadUser !== this.app.user) ||
      this.type === "pcn" ||
      (ocCommentIndex != -1 && this.type === "ocPost")
    ) {
      return false;
    } else {
      return true;
    }
  }
  hasPermissionToDeleteTag(fileDetails: any) {
    if (
      !this.app.permission.attachment.removeTag &&
      fileDetails.uploadUser !== this.app.user
    ) {
      return false;
    } else {
      return true;
    }
  }
}
