<app-modal
  #openInfo
  [modalType]="'userManuals'"
  [modalTitle]="app.text.sidebar.helpInfo"
  [modalMessage]="app.text.sidebar.info"
  [showButtons]="false"
></app-modal>

<!-- Sidebar -->
<div
  class="sidebar"
  id="sidebar-wrapper"
  [ngClass]="{ 'large-sidebar': app.header.showSidebar }"
>
  <div class="sidebar-heading">
    <img
      *ngIf="isCollapsedSidebar()"
      [ngClass]="{
        'user-logo': !app.header.isWideLogo(),
        'user-logo-wider mx-1': app.header.isWideLogo()
      }"
      class="user-logo my-2"
      [src]="app.logo"
    />
    <img
      *ngIf="!isCollapsedSidebar()"
      class="user-logo my-3"
      [src]="app.logo"
    />
    <button
      class="btn btn-customer navbar-brand"
      type="button"
      role="menu"
      data-bs-toggle="dropdown"
      *ngIf="app.header.showSidebar && showClientsList"
    >
      <span *ngIf="app.permission.sidebar.seeClientName">
        {{ app.customers.getText() }}
      </span>
      <i class="far fa-angle-down"></i>
    </button>
    <div class="dropdown-menu m-0 px-1">
      <div class="searchBar d-flex pt-1" *ngIf="cleanCustomers.length > 2">
        <input
          #searchField
          class="form-control searchInput"
          [(ngModel)]="searchedClient"
          (input)="filterCustomers(searchedClient)"
          type="text"
          placeholder="Search"
          (keydown)="checkKey($event)"
        />
        <i class="fa fa-search p-2"></i>
      </div>
      <button
        #dropdownItem
        class="btn dropdown-item m-0 p-1 my-1"
        (click)="resetFilters(); app.routing.navigateCustomer(customer)"
        (keydown)="checkKey($event)"
        *ngFor="let customer of customers"
      >
        {{ app.customers.getText(customer) }}
      </button>
    </div>
    <button class="btn btn-sm btn-close" (click)="app.header.closeNav()">
      <i class="fa fa-times icon-gray"></i>
    </button>
  </div>

  <ul class="navbar-nav">
    <!-- HOME PAGE BUTTON OC -->
    <li
      class="nav-item mt-0"
      *ngIf="app.customer === 'oc'"
      [ngClass]="{ active: app.view === 'oc-home' }"
      [title]="app.text.ocPost.posts"
      (click)="app.routing.navigateOCHome()"
    >
      <a [routerLink]="routerLinkUrl('oc-home')" class="nav-link m-0 p-1">
        <span class="item-icon"
          ><i class="fas fa-folder-open icon-align"></i
        ></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.ocPost.posts
        }}</span>
      </a>
    </li>
    <!-- END -->
    <li
      class="nav-item mt-0"
      *ngIf="showPortalOMButton()"
      [ngClass]="{ active: app.view === 'home' }"
      [title]="app.text.sidebar.cases"
      (click)="app.routing.navigateHome(); app.paginator.focus = 0"
    >
      <a [routerLink]="routerLinkUrl('home')" class="nav-link m-0 p-1">
        <span class="item-icon"
          ><i class="fas fa-folder-open icon-align"></i
        ></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.cases
        }}</span>
      </a>
    </li>
    <li
      class="nav-item"
      *ngIf="showOMButton()"
      [ngClass]="{
        active: app.view === 'home',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.sidebar.OM"
      (click)="app.routing.navigateHome(); app.paginator.focus = 0"
    >
      <a class="nav-link m-0 p-1" [routerLink]="routerLinkUrl('home')">
        <img src="./../assets/om-module-colored.png" class="module-icon" />
        <span class="item-text" *ngIf="app.header.showSidebar">
          {{ app.text.sidebar.OM }}</span
        >
      </a>
    </li>
    <li
      class="nav-item"
      *ngIf="app.permission.RM.seeRMModule"
      [ngClass]="{
        active:
          app.view === 'RM' ||
          app.view === 'part' ||
          app.view === 'manufacturer',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.sidebar.RM"
    >
      <a
        *ngIf="app.permission.RM.seeRMModule"
        (click)="navigateRM()"
        [routerLink]="routerLinkUrl('rm/tree')"
        class="nav-link m-0 p-1"
      >
        <img class="module-icon" src="./../assets/rm-module-colored.png" />
        <span class="item-text" *ngIf="app.header.showSidebar">
          {{ app.text.sidebar.RM }}</span
        >
      </a>
    </li>
    <li
      *ngIf="app.permission.RM.seeRMModule"
      class="nav-item"
      [ngClass]="{
        active: app.view === 'RMSearch',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.RM.riskManagementSearch"
      (click)="navigateRMSearch()"
    >
      <a [routerLink]="routerLinkUrl('rm/search')" class="nav-link m-0 p-1">
        <img
          class="user-logo"
          src="./../assets/rm-search.png"
          class="module-icon"
        />
        <span class="item-text" *ngIf="app.header.showSidebar">
          {{ app.text.RM.riskManagementSearch }}</span
        >
      </a>
    </li>
    <li
      class="nav-item"
      *ngIf="app.permission.operations.seeOperations"
      [ngClass]="{
        active: app.view === 'operations',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.operations.operations"
      (click)="app.routing.navigateOperations(); app.paginator.focus = 0"
    >
      <a class="nav-link m-0 p-1" [routerLink]="routerLinkUrl('operations')">
        <span class="item-icon"><i class="fas fa-toolbox icon-align"></i></span>
        <span class="item-text" *ngIf="app.header.showSidebar">
          {{ app.text.operations.operations }}</span
        >
      </a>
    </li>
    <li
      class="nav-item"
      [ngClass]="{
        active: app.view === 'tasks',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.post.tasks"
      *ngIf="app.permission.post.hasTasks"
      (click)="app.routing.navigateTasks()"
    >
      <a
        [routerLink]="routerLinkUrl('tasks')"
        [ngClass]="{
          'item-sm-sidebar': !app.header.showSidebar
        }"
        class="nav-link m-0 p-1"
      >
        <span class="item-icon"><i class="fas fa-tasks icon-align"></i></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.post.tasks
        }}</span>
        <span
          *ngIf="
            app.tasks.numberOfUncompletedTasks &&
            app.tasks.numberOfUncompletedTasks > 0
          "
          class="badge m-1 selected"
          [ngClass]="{
            counterSmSidebar: !app.header.showSidebar
          }"
        >
          {{ app.tasks.numberOfUncompletedTasks }}
        </span>
      </a>
    </li>
    <li
      *ngIf="app.permission.change.seeNotifications"
      class="nav-item"
      [ngClass]="{
        active: app.view === 'mail',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.sidebar.notification"
      (click)="app.routing.navigateMail('change'); app.paginator.focus = 0"
    >
      <a
        [ngClass]="{
          'item-sm-sidebar': !app.header.showSidebar
        }"
        [routerLink]="routerLinkUrl('mail/change')"
        class="nav-link m-0 p-1"
      >
        <span class="item-icon"><i class="fas fa-bell icon-align"></i></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.notification
        }}</span>
        <span
          *ngIf="app.user != null && unreadCount > 0"
          class="badge m-1 selected"
          [ngClass]="{
            counterSmSidebar: !app.header.showSidebar,
            notificationCollapsed: app.header.showSidebar
          }"
        >
          {{ unreadCount }}
        </span>
      </a>
    </li>
    <li
      class="nav-item"
      [ngClass]="{
        active: app.view === 'se-filter',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      *ngIf="app.permission.thread.hasPermissionToSeeSeSearch"
      [title]="app.text.sidebar.searchInComponent"
      (click)="app.routing.navigateSeFilter()"
    >
      <a
        class="nav-link m-0 p-1 d-flex"
        [routerLink]="routerLinkUrl('se-filter')"
      >
        <span class="item-icon"
          ><i class="fab fa-searchengin icon-align"></i
        ></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.searchInComponent
        }}</span>
      </a>
    </li>
    <li
      *ngIf="app.permission.graphs.view"
      class="nav-item"
      [ngClass]="{
        active: app.view === 'graphs',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.sidebar.charts"
      (click)="app.routing.navigateGraphs(app.graphs.name)"
    >
      <a
        class="nav-link m-0 p-1"
        [routerLink]="routerLinkUrl('graphs/vehicle')"
      >
        <span class="item-icon"
          ><i class="fas fa-signal-alt-3 icon-align"></i
        ></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.charts
        }}</span>
      </a>
    </li>
    <li
      class="nav-item"
      [ngClass]="{
        active: app.view === 'profile',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.sidebar.profile"
      (click)="navigateUserProfile()"
    >
      <a
        *ngIf="
          app.user != null &&
          app.user !== '' &&
          app.profile.ownProfilePicture == null
        "
        class="nav-link m-0 p-1"
        name="{{ app.user }}"
        [ngbTooltip]="app.user"
        [routerLink]="routerLinkUrl('profile')"
      >
        <span class="item-icon"><i class="fas fa-user icon-align"></i></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.profile
        }}</span>
      </a>
      <a
        *ngIf="app.profile.ownProfilePicture != null"
        [ngbTooltip]="app.user"
        name="{{ app.user }}"
        class="nav-link m-0 p-1"
        [routerLink]="routerLinkUrl('profile')"
      >
        <img
          class="user-profile-image"
          [src]="app.file.getUrl(app.profile.ownProfilePicture)"
        />
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.profile
        }}</span>
      </a>
    </li>
    <li
      class="nav-item"
      *ngIf="app.permission.app.users || app.auth.isTeam"
      [ngClass]="{
        active: app.view === 'users' || app.view === 'user',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.sidebar.users"
      (click)="app.routing.navigateUsers(); app.paginator.focus = 0"
    >
      <a class="nav-link m-0 p-1" [routerLink]="routerLinkUrl('users')">
        <span class="item-icon"
          ><i class="fa fa-users-cog icon-align"></i
        ></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.users
        }}</span>
      </a>
    </li>
    <li
      *ngIf="
        (app.customer === 'omp' || app.customer === 'oc') &&
        app.permission.personProfile.see
      "
      class="nav-item"
      [ngClass]="{
        active: app.view === 'user-profile-list',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.app.userList"
      (click)="app.routing.navigateUserProfiles(); app.paginator.focus = 0"
    >
      <a class="nav-link m-0 p-1">
        <span class="item-icon"><i class="fa fa-users icon-align"></i></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.app.userList
        }}</span>
      </a>
    </li>
    <li
      class="nav-item"
      [title]="app.text.sidebar.logout"
      (click)="app.auth.logout()"
      [ngClass]="{ 'd-flex justify-content-center': !app.header.showSidebar }"
    >
      <a id="logout-button" class="nav-link m-0 p-1">
        <span class="item-icon"
          ><i class="fas fa-sign-out-alt icon-align"></i
        ></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.logout
        }}</span>
      </a>
    </li>
    <li class="bottom-line my-3"></li>
    <li
      *ngIf="app.permission.app.import"
      class="nav-item"
      [ngClass]="{
        active: app.view === 'import',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.sidebar.import"
      (click)="app.routing.navigateImport()"
    >
      <a class="nav-link m-0 p-1" [routerLink]="routerLinkUrl('import')">
        <span class="item-icon"><i class="fa fa-upload icon-align"></i></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.import
        }}</span>
      </a>
    </li>
    <li
      *ngIf="app.permission.debug.view"
      class="nav-item"
      [ngClass]="{
        active: app.view === 'debug',
        'd-flex justify-content-center': !app.header.showSidebar
      }"
      [title]="app.text.sidebar.export"
      (click)="app.routing.navigateDebug()"
    >
      <a class="nav-link m-0 p-1" [routerLink]="routerLinkUrl('debug')">
        <span class="item-icon"><i class="fa fa-download icon-align"></i></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.export
        }}</span>
      </a>
    </li>
    <li
      *ngIf="app.customer != 'oc'"
      class="nav-item"
      [title]="app.text.sidebar.help"
      (click)="openInfo.open()"
      [ngClass]="{ 'd-flex justify-content-center': !app.header.showSidebar }"
    >
      <!-- class="btn nav-link expand-clickable-area m-0 p-1" -->
      <a class="nav-link m-0 p-1">
        <span class="item-icon"
          ><i class="far fa-question-circle icon-align"></i
        ></span>
        <span class="item-text" *ngIf="app.header.showSidebar">{{
          app.text.sidebar.help
        }}</span>
      </a>
    </li>
  </ul>
</div>
<!-- /#sidebar-wrapper -->
