<ng-template #content let-modal>
  <div class="modal-header">
    <div class="modal-title">{{ app.text.ocPost.postQuestion }}</div>
    <button class="btn pull-right" (click)="closeModal()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div class="text-center post-header">
      <b>{{ app.text.ocPost.postQuestion }}</b>
    </div> -->
    <div class="d-flex">
      <div class="d-flex flex-column flex-grow-1">
        <input
          [(ngModel)]="app.OCHome.postDoc.title"
          type="text"
          placeholder="{{ app.text.ocPost.postTitlePlaceholder }}"
          class="input my-2"
        />
        <textarea
          [(ngModel)]="app.OCHome.postDoc.message"
          placeholder="{{ app.text.ocPost.postMessagePlaceholder }}"
          class="input"
        ></textarea>
      </div>
      <div class="d-flex flex-column flex-shrink-1">
        <div class="dropdown mb-auto my-1">
          <button
            class="btn btn-sm dropdown-toggle btn-tag ms-1"
            type="button"
            id="dropdownTags"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            [ngStyle]="
              app.OCPostItem.getTagColor(
                app.fieldId.ocPost.tagCategory,
                app.OCHome.postDoc['tag']
              )
            "
          >
            {{
              app.field.getOptionText(
                app.fieldId.ocPost.tagCategory,
                app.OCHome.postDoc["tag"]
              )
            }}
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let tag of app.OCHome.tags">
              <a
                class="dropdown-item pointer"
                (click)="app.OCHome.updateTag(tag)"
                >{{
                  app.field.getOptionText(app.fieldId.ocPost.tagCategory, tag)
                }}</a
              >
            </li>
          </ul>
        </div>
        <button
          type="button"
          (click)="app.OCPost.savePost(); closeModal()"
          class="btn btn-sm btn-post text-center ms-1"
          [disabled]="isDisabled()"
        >
          <i class="fa-solid fa-paper-plane-top"></i>
          {{ app.text.ocPost.post }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
