import { ComponentSettings, FieldTypes, empty } from "../../settings/settings";

const settings = {
  permission: {
    edit: false,
    editOwn: false,
  },
  text: {
    OC: {
      de: "Obsolescence Community",
      en: "Obsolescence Community",
      nl: "Obsolescence Community",
    },
    postQuestion: {
      de: "What do you want to talk about?",
      en: "What do you want to talk about?",
      nl: "What do you want to talk about?",
    },
    postTitlePlaceholder: {
      de: "Write something meaningful for the topic you want to write about",
      en: "Write something meaningful for the topic you want to write about",
      nl: "Write something meaningful for the topic you want to write about",
    },
    postMessagePlaceholder: {
      de: "The related post should address issues concerning obsolescence, the supply chain, or a specific Manufacturer Part Number (MPN). If applicable, please feel free to include the MPN in the content as well.",
      en: "The related post should address issues concerning obsolescence, the supply chain, or a specific Manufacturer Part Number (MPN). If applicable, please feel free to include the MPN in the content as well.",
      nl: "The related post should address issues concerning obsolescence, the supply chain, or a specific Manufacturer Part Number (MPN). If applicable, please feel free to include the MPN in the content as well.",
    },
    own: {
      de: "Post Owner",
      en: "Post Owner",
      nl: "Post Owner",
    },
    title: {
      de: "Title",
      en: "Title",
      nl: "Title",
    },
    description: {
      de: "Description",
      en: "Description",
      nl: "Description",
    },
    postImage: {
      de: "Post Image",
      en: "Post Image",
      nl: "Post Image",
    },
    newPost: {
      de: "Create New Post",
      en: "Create New Post",
      nl: "Create New Post",
    },
    posts: { de: "Posts", en: "Posts", nl: "Posts" },
    post: { de: "Post", en: "Post", nl: "Post" },
    likes: { de: "Likes", en: "Likes", nl: "Likes" },
    comments: { de: "Comments", en: "Comments", nl: "Comments" },
    comment: { de: "Comment", en: "Comment", nl: "Comment" },
    sortToOldest: {
      de: "Sort Newest",
      en: "Sort Newest",
      nl: "Sort Newest",
    },
    sortToNewest: {
      de: "Sort Oldest",
      en: "Sort Oldest",
      nl: "Sort Oldest",
    },
  },
  field: {
    tagCategory: {
      type: FieldTypes.options,
      text: {
        de: "Kategorie",
        en: "Category",
        nl: "Category",
      },
      options: {
        general: {
          text: {
            de: "#general",
            en: "#general",
            nl: "#general",
          },
          color: "#8FA3A3",
          backgroundColor: "#F5F7F7",
        },
        searchItem: {
          text: {
            de: "#search-items",
            en: "#search-items",
            nl: "#search-items",
          },
          color: "#660000",
          backgroundColor: "#FFDADA",
        },
        riskMitigation: {
          text: {
            de: "#risk-mitigation",
            en: "#risk-mitigation",
            nl: "#risk-mitigation",
          },
          color: "#003D7A",
          backgroundColor: "#DCEDFF",
        },
        strategy: {
          text: {
            de: "#strategy",
            en: "#strategy",
            nl: "#strategy",
          },
          color: "#FF4000",
          backgroundColor: "#FFEEE8",
        },
        compliance: {
          text: {
            de: "#compliance",
            en: "#compliance",
            nl: "#compliance",
          },
          color: "#008000",
          backgroundColor: "#DCFFDC",
        },
        pcnPdn: {
          text: {
            de: "#pcn-pdn",
            en: "#pcn-pdn",
            nl: "#pcn-pdn",
          },
          color: "#964B00",
          backgroundColor: "#FFEFDE",
        },
        supplyChain: {
          text: {
            de: "#supply-chain",
            en: "#supply-chain",
            nl: "#supply-chain",
          },
          color: "#FFCC66",
          backgroundColor: "#FFFAF1",
        },
        technical: {
          text: {
            de: "#technical",
            en: "#technical",
            nl: "#technical",
          },
          color: "#500EA4",
          backgroundColor: "#EEE3FD",
        },
        ressource: {
          text: {
            de: "#ressources",
            en: "#ressources",
            nl: "#ressources",
          },
          color: "#e5d0a7",
          backgroundColor: "#FDFBF7",
        },
        introduction: {
          text: {
            de: "#introduction",
            en: "#introduction",
            nl: "#introduction",
          },
          color: "#fe7096",
          backgroundColor: "#FFF2F6",
        },
      },
    },
    title: {
      type: FieldTypes.value,
      text: {
        de: "Title",
        en: "Title",
        nl: "Title",
      },
      required: true,
    },
    message: {
      type: FieldTypes.value,
      text: {
        de: "Message",
        en: "Message",
        nl: "Message",
      },
      required: true,
    },
  },
  url: {
    defaultImage: "./assets/dummy.jpg",
  },
  list: {
    boxesLeft: empty,
    mainBox: [],
    itemsBox: [],
    filesBox: [],
    sendBox: [],
    itemDetail: [],
  },
};

export const ocPost: ComponentSettings<typeof settings> = settings;
